import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import EmployeeBlockLarge from 'components/EmployeeBlockLarge'
import EmployeeCard from 'components/EmployeeCard'

import EmployeeType from 'types/EmployeeType'

import * as css from './EmployeesBlock.module.scss'

class EmployeesBlock extends React.PureComponent {
    static propTypes = {
        employees: PropTypes.arrayOf(PropTypes.shape(EmployeeType)).isRequired,
        labels: PropTypes.shape({
            contactLabel: PropTypes.string.isRequired,
            contactMeOrMyColleagues: PropTypes.string.isRequired,
            contactPersons: PropTypes.string.isRequired,
            viewAllEmployees: PropTypes.string.isRequired
        }).isRequired,
        image: PropTypes.shape({
            url: PropTypes.string
        }),
        negative: PropTypes.bool,
        url: PropTypes.string.isRequired
    }

    static defaultProps = {
        negative: false
    }

    render() {
        const { employees, labels, image, negative, url } = this.props

        if (employees.length === 0) return null

        if (employees.length === 1) {
            return (
                <EmployeeBlockLarge
                    employee={employees[0]}
                    labels={labels}
                    image={image}
                    url={url}
                />
            )
        }

        return (
            <div
                className={cn('list-items', {
                    negative: negative
                })}
            >
                <h2 className="h4">{labels.contactPersons}</h2>
                <div className="list-items-content">
                    <ul
                        className={cn(css.employees, {
                            [css.negative]: negative,
                            [css.multiple]: employees.length > 2
                        })}
                    >
                        {employees.map((employee, index) => (
                            <li key={index}>
                                <EmployeeCard
                                    key={index.id}
                                    {...employee}
                                    negative={false}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                {url && (
                    <a className="see-all shortcut" href={url}>
                        {labels.viewAllEmployees}
                    </a>
                )}
            </div>
        )
    }
}

export default EmployeesBlock
