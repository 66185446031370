import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import * as css from './NoResults.scss'

const NoResults = ({
    labels,
    light,
    margin,
    onReset,
}) => (
    <div 
        className={cn(css.wrapper, { 
            [css.light]: light,
            [css.margin]: margin
        })}
        id="no-results-container"
    >
        <h3>{labels && labels.noResultsHeading}</h3>
        <p>{labels && labels.noResultsText}</p>

        {onReset && (
            <button
                onClick={onReset}
                className={css.button}
            >{labels && labels.resetFilter}</button>
        )}
    </div>
)

NoResults.propTypes = {
    labels: PropTypes.shape({
        noResultsHeading: PropTypes.string.isRequired,
        noResultsText: PropTypes.string.isRequired,
        resetFilter: PropTypes.string,
    }).isRequired,
    light: PropTypes.bool.isRequired,
    margin: PropTypes.bool.isRequired,
    onReset: PropTypes.func,
}

NoResults.defaultProps = {
    light: false,
    margin: false,
}

export default NoResults
