import React from 'react'
import PropTypes from 'prop-types'

import FilterList from 'hoc/FilterList'
import FilterTable from 'components/FilterTable'
import WeekMonthStatistic from 'components/WeekMonthStatistic'

import { format } from 'utils'

import * as css from './WeekMonthStatistics.module.scss'

export class WeekMonthStatistics extends React.Component {
    static propTypes = {
        labels: PropTypes.shape({
            showFilters: PropTypes.string.isRequired,
            hideFilters: PropTypes.string.isRequired,
            filterButton: PropTypes.string.isRequired,
            reset: PropTypes.string.isRequired,
            noResultsHeading: PropTypes.string.isRequired,
            noResultsText: PropTypes.string.isRequired,
            resetFilter: PropTypes.string.isRequired,
            closedReports: PropTypes.string.isRequired,
            closedReportsDescription: PropTypes.string.isRequired,
            noAccessReportsDescription: PropTypes.string.isRequired,
            paginationNext: PropTypes.string.isRequired,
            paginationPrev: PropTypes.string.isRequired,
            paginationPage: PropTypes.string.isRequired,
            resultsOnFormatLabel: PropTypes.string,
            resultsCountFormatLabel: PropTypes.string
        }).isRequired,
        tabs: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            link: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
        onToggle: PropTypes.func.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            reports: PropTypes.array.isRequired,
        })).isRequired,
        listHeading: PropTypes.string,
        shown: PropTypes.number,
        totalItems: PropTypes.number.isRequired,
        searchValue: PropTypes.string
    }

    static defaultProps = {
        labels: {
            resultsCountFormatLabel: "{0} treff",
            resultsOnFormatLabel: "på {0}"
        }
    }

    render() {
        const {
            items,
            labels,
            shown,
            searchValue,
            listHeading,
            totalItems,
        } = this.props

        return (
            <FilterTable
                classNames={{
                    row: css.row
                }}
                component={WeekMonthStatistic}
                headers={["", ""]}
                items={items}
                labels={labels}
                onToggle={this.props.onToggle}
                rows={[item => item.text]}
                shown={shown}
                type="weekmonthstatistic"
                searchValue={searchValue}
                caption={listHeading}
                totalItems={totalItems}
            />
        )
    }

}

export default FilterList(WeekMonthStatistics)
