import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import cn from 'classnames'

import * as css from './CtaBlock.scss'

const CtaBlock = ({
    heading,
    buttons,
}) => (
    <div className={css.ctaBlock}>
        <div className={css.ctaBlockWrapper}>
            <div className={css.ctaBlockContainer}>
                {heading && <h2 className={css.heading}>{heading}</h2>}
                {
                    buttons && buttons.length > 0 && (
                        <div className={css.buttonsHolder}>
                            {buttons.map((button, index) => (
                                <React.Fragment key={index}>
                                    <Link {...button} className={cn('button secondary compact', css.button)} />
                                </React.Fragment>
                            ))}
                        </div>
                    )
                }
            </div>
        </div>
    </div>
)

CtaBlock.propTypes = {
    heading: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
}

export default CtaBlock
