import React from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import throttle from 'lodash/throttle'

import Filter from 'components/Filter'

import FilterType from 'types/FilterType'
import 'react-dates/lib/css/_datepicker.css'
import './FilterDateRange.scss'

class FilterDateRange extends React.Component {
    static propTypes = {
        ...FilterType,
        labels: PropTypes.shape({
            title: PropTypes.string.isRequired,
            addFilter: PropTypes.string.isRequired,
            customDateRange: PropTypes.string.isRequired,
            startDate: PropTypes.string.isRequired,
            endDate: PropTypes.string.isRequired,
        }),
        onChange: PropTypes.func.isRequired,
        value: PropTypes.shape({
            startDate: PropTypes.object,
            endDate: PropTypes.object
        }),
        negative: PropTypes.bool
    }

    state = {
        focusedInput: null,
        orientation: 'horizontal',
        withPortal: true,
        numberOfMonths: 2
    }

    componentDidMount() {
        if(typeof window !== "undefined") {
            this._onResize()
            this._throttledOnResize = throttle(this._onResize, 200)
            window.addEventListener('resize', this._throttledOnResize)
        }
    }

    _onResize = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        let state = {}

        // Use fullscreen datepicker only smaller screens
        if(width >= 890 && this.state.withPortal) {
            state.withPortal = false
        } else if(width < 890 && !this.state.withPortal) {
            state.withPortal = true
        }

        // Change calendar orientation for smaller screens
        /*        
        if(width >= 750 && this.state.orientation === 'vertical') {
            state.orientation = 'horizontal'
        } else if (width < 750 && this.state.orientation === 'horizontal') {
            state.orientation = 'vertical'
        }        
        */

        if(width >= 750 && this.state.numberOfMonths === 1) {
            state.numberOfMonths = 2
        } else if (width < 750 && this.state.numberOfMonths === 2) {
            state.numberOfMonths = 1
        }

        // Only update the state if we have made any modifications
        if(Object.keys(state).length) this.setState(state)
    }

    _handleDatesChange = dates => this.props.onChange(dates)

    _handleFocusChange = focusedInput => {
        const inputs = document.querySelectorAll('.DateInput__input')
 
        Array.from(inputs)
            .forEach(input => {
                input && input.blur()
            })

        this.setState({ focusedInput })
    }

    render() {
        const { 
            labels,
            value,
            negative,
        } = this.props

        const { 
            focusedInput,
            orientation,
            withPortal,
            numberOfMonths
        } = this.state

        let startDate
        let endDate

        if(value) {
            if(value.startDate) startDate = value.startDate
            if(value.endDate) endDate = value.endDate
        }

        return (
            <Filter negative={negative} title={labels.title}>
                <DateRangePicker
                    anchorDirection="right"
                    endDate={endDate}
                    endDateId="endDateId"
                    endDatePlaceholderText={labels.endDate}
                    focusedInput={focusedInput}
                    isOutsideRange={() => false}
                    numberOfMonths={numberOfMonths}
                    onDatesChange={this._handleDatesChange}
                    onFocusChange={this._handleFocusChange}
                    orientation={orientation}
                    showClearDates={true}
                    startDate={startDate}
                    startDateId="startDateId"
                    startDatePlaceholderText={labels.startDate}
                    withPortal={withPortal}
                />
            </Filter>
        )
    }
}

export default FilterDateRange
