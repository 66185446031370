import React from 'react'
import LinkWithIcon from 'components/LinkWithIcon'
import { DatePeriod } from '../../constants';
import PropTypes from 'prop-types'
import cn from 'classnames'

const MarketInsightCalendarEvent = ({
    datePeriod,
    heading,
    url,
    isRestricted,
    shortDescription,
    availableAt
}) => {

    return (
        <div
            className={cn('market-insight-calendar-date__event', {
                [`market-insight-calendar-date__event_${datePeriod}`]: datePeriod,
            })}
        >
            <div className="market-insight-calendar-date__event-heading-row">
                {url ? (
                    <LinkWithIcon
                        text={heading}
                        url={url}
                        hideIcon={!isRestricted}
                        type={
                            isRestricted
                                ? LinkWithIcon.types.restricted
                                : LinkWithIcon.types.regular
                        }
                        className="market-insight-calendar-date__event-link-heading"
                    />
                ) : (
                    <strong className={cn("market-insight-calendar-date__event-heading", {
                        [`market-insight-calendar-date__event-heading_${datePeriod}`]: datePeriod
                    })}>
                        {heading}
                    </strong>
                )}
            </div>
            
            <div className="market-insight-calendar-date__event-text-row">
                {shortDescription && (
                    <span className="market-insight-calendar-date__event-text">
                        {shortDescription}
                    </span>
                )}
                {availableAt && (
                    <span className="market-insight-calendar-date__event-text">
                        {availableAt}
                    </span>
                )}
            </div>
        </div>
    )
}

MarketInsightCalendarEvent.propTypesMeta = {
    datePeriod: 'exclude',
}

MarketInsightCalendarEvent.propTypes = {
    datePeriod: PropTypes.oneOf(Object.values(DatePeriod)),
    isRestricted: PropTypes.bool.isRequired,
    shortDescription: PropTypes.string,
    availableAt: PropTypes.string,
    heading: PropTypes.string.isRequired,
    url: PropTypes.string
}

export default MarketInsightCalendarEvent
