import PropTypes from 'prop-types'

const subCategory = () => CategoryType

const CategoryType = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(subCategory)
}


export default CategoryType
