import React from 'react'
import Link from 'components/Link'
import LinkWithIcon from 'components/LinkWithIcon'
import ResponsiveImage from 'components/ResponsiveImage'
import PropTypes from 'prop-types'
import PoorText from 'components/PoorText'
import cn from 'classnames';

const MarketInsightUsefulPagesBlockItem = ({ icon, title, introHtml, link }) => {
    return (
        <article className="marketInsight-useful-pages-block__item">
            <div className={cn("marketInsight-useful-pages-block__item-icon-container", {
                "marketInsight-useful-pages-block__item-icon-container_no-image": !icon
            })}>
                {icon && (
                    <ResponsiveImage
                        {...icon}
                        className="marketInsight-useful-pages-block__item-icon responsive-image_size_market-insight-useful-pages-block"
                    />
                )}
            </div>
            {title && (
                <h2 className="marketInsight-useful-pages-block__item-title">{title}</h2>
            )}
            {introHtml && (
                <PoorText
                    className="marketInsight-useful-pages-block__item-intro poor-text_in-market-insight-useful-pages-block"
                    text={introHtml}
                />
            )}
            {link && (
                <LinkWithIcon
                    {...link}
                    className="marketInsight-useful-pages-block__item-link"
                />
            )}
        </article>
    )
}

MarketInsightUsefulPagesBlockItem.propTypes = {
    icon: PropTypes.exact(ResponsiveImage.propTypes),
    title: PropTypes.string,
    introHtml: PropTypes.string,
    link: PropTypes.exact(Link.propTypes)
}

export default MarketInsightUsefulPagesBlockItem
