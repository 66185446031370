import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components/Tooltip'
import PoorText from 'components/PoorText'
import SelectFiltersPanel from 'components/SelectFiltersPanel'
import Icon from 'components/Icon'
import NoResults from 'components/NoResults'
import Loader from 'components/Loader'
import CustomsQuotasViewTermsAndConditions from 'components/CustomsQuotasViewTermsAndConditions'
import FILTER_SIZES from 'components/SelectFilter/filter-sizes'
import FILTER_VARIANTS from 'components/SelectFilter/filter-variants'
import TOOLTIP_POSITIONS from "components/Tooltip/tooltip-positions";
import api from 'js/api-helper'
import formHelper from 'js/form-helper'
import useDidUpdateEffect from '../../hooks/use-did-update-effect'
import './CustomsQuotasTable.scss'

const CustomsQuotasTable = ({
    heading,
    introHtml,
    loadItemsEndpoint,
    dataProvidedByHtml,
    viewTermsAndConditions,
    lang = {
        speciesColumnLabel: "Art",
        productGroupColumnLabel: "Produkt",
        commodityCodeColumnLabel: "EU varennumer",
        orderNumberColumnLabel: "Løpenummer",
        quotaTypeColumnLabel: "Kvotetype",
        quotaPeriodColumnLabel: "Kvoteperiode",
        quotaVolumeColumnLabel: "Kvote (Tonn)",
        quotaBalanceColumnLabel: "Kvote - gjenstående (Tonn)",
        quotaDutyRateColumnLabel: "Tollsats",
        quotaImportDateColumnLabel: "Importdato",
        noFilterResultsText: "Ingen resultater",
        noListResultsHeading: "Beklager, ingen treff",
        noListResultsText: "Filteret kan være for detaljert. Du kan prøve å justere det eller nullstille for å prøve på nytt.",
        serverErrorText: "Serverfeil",
        expandFiltersLabel: "Utvid filtre",
        openProductInfoAriaLabel: "Åpne produktinfo",
        closeProductInfoAriaLabel: "Lukk produktinfo"
    }
}) => {
    const [query, setQuery] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [results, setResults] = useState(null)

    const initLoadedFilter = filter => {
        formHelper.updateFilterFromQuery(query, filter.name, filter.items);
    };

    const loadResults = () => {
        setIsLoading(true)
        setError(null)
        api.execute(loadItemsEndpoint, query)
            .then(response => {
                setIsLoading(false)
                if (response.success) {
                    response.data.filters.forEach(initLoadedFilter);
                    setResults(response.data)
                } else {
                    setError(response.errorMessage)
                }
            })
            .catch(() => {
                setError(lang.serverErrorText)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setQuery(formHelper.fillQueryFromPageQueryString({}))
    }, [])

    useDidUpdateEffect(() => {
        formHelper.updatePageQueryString(query)
        loadResults()
    }, [query]);

    return (
        <div className='customs-quotas-table'>
             <div className='customs-quotas-table__container'>
                <div className='customs-quotas-table__content'>
                    <div className='customs-quotas-table__heading-holder'>
                        {heading && <h2 className='customs-quotas-table__heading'>{heading}</h2>}
                    </div>                    
                    {introHtml && <PoorText text={introHtml} className='customs-quotas-table__intro poor-text_in-customs-quotas-table' />}
                </div>
                {results && results.filters && (
                    <div className='customs-quotas-table__filters'>
                        <SelectFiltersPanel
                            query={query}
                            selectFilters={results.filters}
                            expanderLabel={lang.expandFiltersLabel}
                            noResultsText={lang.noFilterResultsText}
                            multipleSelection={false}
                            filterSize={FILTER_SIZES.LARGE}
                            filterVariant={FILTER_VARIANTS.FLAT}
                            onFilterChange={setQuery}
                            overrideFiltersFromServer={true}
                            className='select-filters-panel_in-customs-quotas'
                        />
                    </div>
                )}
                <div className='customs-quotas-table__data-container'>
                    {results && results.items && results.items.length > 0 ?
                        <Fragment>
                            <div className='customs-quotas-table__table-container'>
                                <table className='customs-quotas-table__table'>
                                    <thead className='customs-quotas-table__table-head'>
                                        <tr>
                                            <th className='customs-quotas-table__table-cell'>{lang.speciesColumnLabel}</th>
                                            <th className='customs-quotas-table__table-cell'>{lang.productGroupColumnLabel}</th>
                                            <th className='customs-quotas-table__table-cell'>{lang.commodityCodeColumnLabel}</th>
                                            <th className='customs-quotas-table__table-cell'>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{lang.orderNumberColumnLabel}</th>
                                                            <th className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{lang.quotaTypeColumnLabel}</th>
                                                            <th className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{lang.quotaPeriodColumnLabel}</th>
                                                            <th className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{lang.quotaVolumeColumnLabel}</th>
                                                            <th className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{lang.quotaBalanceColumnLabel}</th>
                                                            <th className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{lang.quotaDutyRateColumnLabel}</th>
                                                            <th className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{lang.quotaImportDateColumnLabel}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.items.map((row, rowIndex) => (
                                            <tr key={row.key} className='customs-quotas-table__table-row'>
                                                <td className='customs-quotas-table__table-cell'>{row.species}</td>
                                                <td className='customs-quotas-table__table-cell'>{row.product}</td>
                                                <td className='customs-quotas-table__table-cell'>
                                                    <Tooltip 
                                                        openBtnAriaLabel={lang.openProductInfoAriaLabel}
                                                        closeBtnAriaLabel={lang.closeProductInfoAriaLabel}
                                                        content={row.info} 
                                                        className='customs-quotas-table__info-icon'
                                                        position={results.items ? 
                                                            (rowIndex > results.items.length - 3 ? TOOLTIP_POSITIONS.TOP : TOOLTIP_POSITIONS.BOTTOM) 
                                                        : TOOLTIP_POSITIONS.BOTTOM}
                                                    >
                                                        <Icon use="info" className="customs-quotas-table__icon" />
                                                    </Tooltip>
                                                    <span>{row.commodityCode}</span>
                                                </td>
                                                <td className='customs-quotas-table__table-cell' colSpan="7">
                                                    {row.quotas && row.quotas.length > 0 ? 
                                                        <table>
                                                            <tbody>
                                                                {row.quotas.map(quota => (
                                                                    <tr key={quota.key} className='customs-quotas-table__table-row'>
                                                                        <td className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{quota.orderNumber}</td>
                                                                        <td className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{quota.quotaType}</td>
                                                                        <td className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{quota.quotaPeriod}</td>
                                                                        <td className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{quota.quotaVolume}</td>
                                                                        <td className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{quota.quotaBalance}</td>
                                                                        <td className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{quota.quotaDutyRate}</td>
                                                                        <td className='customs-quotas-table__table-cell customs-quotas-table__table-cell_inner'>{quota.quotaImportDate}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {(dataProvidedByHtml || viewTermsAndConditions) && (
                                <div className='customs-quotas-table__credits'>
                                    {dataProvidedByHtml && 
                                        <PoorText text={dataProvidedByHtml} className='customs-quotas-table__credits-text poor-text_in-customs-quotas-table' />
                                    }
                                    {viewTermsAndConditions && 
                                        <CustomsQuotasViewTermsAndConditions {...viewTermsAndConditions} />
                                    }
                                </div>
                            )}
                        </Fragment>
                    : null}
                </div>
                {results && results.items && results.items.length === 0 && (
                    <NoResults
                        labels={{
                            noResultsHeading: lang.noListResultsHeading,
                            noResultsText: lang.noListResultsText
                        }}
                        light
                    />
                )}
                {isLoading && (
                    <div className="customs-quotas-table__loading">
                        <Loader />
                    </div>
                )}
                {error && <div className='customs-quotas-table__error'>{error}</div>}
            </div>
        </div>
    )
}

CustomsQuotasTable.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    loadItemsEndpoint: PropTypes.string.isRequired,
    dataProvidedByHtml: PropTypes.string,
    viewTermsAndConditions: PropTypes.exact(CustomsQuotasViewTermsAndConditions.propTypes),
    lang: PropTypes.shape({
        speciesColumnLabel: PropTypes.string.isRequired,
        productGroupColumnLabel: PropTypes.string.isRequired,
        commodityCodeColumnLabel: PropTypes.string.isRequired,
        orderNumberColumnLabel: PropTypes.string.isRequired,
        quotaTypeColumnLabel: PropTypes.string.isRequired,
        quotaPeriodColumnLabel: PropTypes.string.isRequired,
        quotaVolumeColumnLabel: PropTypes.string.isRequired,
        quotaBalanceColumnLabel: PropTypes.string.isRequired,
        quotaDutyRateColumnLabel: PropTypes.string.isRequired,
        quotaImportDateColumnLabel: PropTypes.string.isRequired,
        noFilterResultsText: PropTypes.string.isRequired,
        noListResultsHeading: PropTypes.string.isRequired,
        noListResultsText: PropTypes.string.isRequired,
        serverErrorText: PropTypes.string.isRequired,
        expandFiltersLabel: PropTypes.string.isRequired,
        closeProductInfoAriaLabel: PropTypes.string.isRequired,
        openProductInfoAriaLabel: PropTypes.string.isRequired,
    }).isRequired
}

export default CustomsQuotasTable
