import PropTypes from 'prop-types'

import CategoryType from 'types/CategoryType'
import AttachmentType from 'types/AttachmentType'
import EmployeeType from 'types/EmployeeType'

const MaterialType = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        focusPoint: PropTypes.object
    }),
    intro: PropTypes.string,
    employees: PropTypes.arrayOf(PropTypes.shape(EmployeeType)).isRequired,
    attachment: PropTypes.shape(AttachmentType),
    species: PropTypes.arrayOf(PropTypes.shape(CategoryType)),
    countries: PropTypes.arrayOf(PropTypes.shape(CategoryType)),
    languages: PropTypes.arrayOf(PropTypes.shape(CategoryType)),
    enriched: PropTypes.bool,
    coFinancing: PropTypes.bool,
    hideOrderButton: PropTypes.bool
}

export default MaterialType
