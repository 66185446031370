import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import debounce from 'lodash/debounce'

import SearchQuick from 'hoc/SearchQuick'

import SearchResult from 'components/SearchResult'
import SearchWrapper from 'components/SearchWrapper'

import * as css from './SearchBlock.scss'

class SearchBlock extends React.Component {
    static propTypes = {
        labels: PropTypes.shape({
            seeAllResults: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
            hasAccessLabel: PropTypes.string.isRequired,
            noResultsFormat: PropTypes.string.isRequired,
        }),
        tagline: PropTypes.string,
        searchPlaceholder: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string.isRequired,
            imageTone: PropTypes.oneOf(['dark', 'light'])
        }).isRequired,
        language: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        searchPageUrl: PropTypes.string.isRequired,
        searching: PropTypes.bool.isRequired,
        search: PropTypes.func.isRequired,
        searchAriaLabel: PropTypes.string,
        results: PropTypes.array.isRequired,
        totalCount: PropTypes.number.isRequired,
        moreLink: PropTypes.string,
        submittedSearchQuery: PropTypes.string,
        error: PropTypes.string,
        searchErrorLabel: PropTypes.string.isRequired,
    }

    state = {
        q: ""
    }

    constructor(props) {
        super(props)

        this._debouncedSearch = debounce(this._search, 500)
    }

    _search = (query) => {
        this.props.search(query)
    }

    _handleOnChange = (e) => {
        this.q = e.target.value
        this.setState({ q: e.target.value }, () => {
            this._debouncedSearch(this.state.q)
        })
    }

    _handleOnSubmit = (e) => {
        e.preventDefault()

        let location = this.props.searchPageUrl

        if(this.q) {
            location += `?q=${this.q}`
        }

        window.location = location
    }

    render() {
        const {
            labels,
            tagline,
            searchPlaceholder,
            image,
            searching,
            results,
            searchPageUrl,
            submittedSearchQuery,
            error,
            searchErrorLabel,
            searchAriaLabel
        } = this.props

        return (
            <div className={css.searchblock} style={{
                backgroundImage: `url(${image && image.url.downResizeMin(1440)})`,
                backgroundPosition: (image && image.focusPoint) && `${image.focusPoint.x}% ${image.focusPoint.y}%`
            }}>
                <form className={css.form} onSubmit={this._handleOnSubmit}>
                    {tagline &&
                        <h2 className={cn('h1', css.tagline, {
                            [css.light]: image && image.imageTone === 'light'
                        })}>{tagline}</h2>
                    }
                    <label
                        className={css.label}
                        htmlFor="search-overlay-input"
                    >{searchPlaceholder}</label>
                    <SearchWrapper
                        autoComplete="off"
                        buttonLabel={labels.search}
                        className={css.searchWrapper}
                        id="search-overlay-input"
                        name="q"
                        negative={true}
                        onChange={this._handleOnChange}
                        onClick={this._handleOnSubmit}
                        placeholder={searchPlaceholder}
                        searching={searching}
                        value={this.state.q}
                        ariaLabel={searchAriaLabel}
                    />
                    {this.state.q.length > 0 && (
                        <div className={css.searchContainer}>
                            <div aria-live="polite" className={css.searchResults}>
                                {results.length > 0 && results.map(r => (
                                    <SearchResult
                                        {...r}
                                        hasAccessLabel={labels && labels.hasAccessLabel}
                                        key={r.id}
                                    />
                                ))}
                                {results.length > 0 && (
                                    <a
                                        className={css.seeAllResults}
                                        href={searchPageUrl + '?q=' + this.state.q}
                                    >{labels.seeAllResults}</a>
                                )}
                                {!searching && submittedSearchQuery && results.length === 0 && (
                                    <div className={css.noResults}>
                                        {labels && labels.noResultsFormat.format(submittedSearchQuery)}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {error && (
                        <div className={css.searchContainer}>
                            <div
                                className={css.error}
                                dangerouslySetInnerHTML={{ __html: searchErrorLabel }}
                            />
                        </div>
                    )}
                </form>
            </div>
        )
    }
}

export default SearchQuick(SearchBlock)
