import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import throttle from 'lodash/throttle'
import './SharePanel.scss'
import SharePanelLink from './SharePanelLink'

const SharePanel = ({
    inArticle = false,
    shareLinks,
    hasSidebar = true
}) => {
    const [sticky, setSticky] = useState(false)
    const [faded, setFaded] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            var el = document.querySelector('section.article-content')

            if (el && el.getBoundingClientRect) {
                const { top, bottom } = el.getBoundingClientRect()

                if (bottom <= 370) {
                    setFaded(true)
                } else {
                    setFaded(false)
                }

                if (top >= 150) {
                    setSticky(false)
                } else {
                    setSticky(true)
                }
            }
        }

        const throttledScroll = throttle(handleScroll, 100)
        window.addEventListener('scroll', throttledScroll)

        return () => {
            window.removeEventListener('scroll', throttledScroll)
        }
    }, [])

    return (
        <div
            className={cn('share-panel', {
                'share-panel_sticky': sticky,
                'share-panel_in-article': inArticle,
                'share-panel_has-sidebar': hasSidebar,
                'share-panel_faded': faded
            })}
        >
            <div className='share-panel__items'>
                {shareLinks &&
                    shareLinks.map((item, index) => {
                        return (
                            <SharePanelLink {...item} key={index} />
                        )
                    })}
            </div>
        </div>
    )
}

SharePanel.propTypes = {
    inArticle: PropTypes.bool.isRequired,
    shareLinks: PropTypes.arrayOf(
        PropTypes.exact(SharePanelLink.propTypes)
    ),
    hasSidebar: PropTypes.bool.isRequired
}

export default SharePanel
