import PropTypes from 'prop-types'

const EmployeeType = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    country: PropTypes.string,
    intro: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        imageTone: PropTypes.string,
        focusPoint: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        })
    }),
    bulletList: PropTypes.arrayOf(PropTypes.string),
    phone: PropTypes.string,
    secondaryPhone: PropTypes.string,
    email: PropTypes.string,
    url: PropTypes.string,
    modalLabels: PropTypes.shape({
        readMore: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        close: PropTypes.string,
        downloadImage: PropTypes.string,
        showMore: PropTypes.string,
    }),
    latestChronicles: PropTypes.object,
    isCeo: PropTypes.bool
}

export default EmployeeType
