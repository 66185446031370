import React from 'react'
import MarketInsightCtaLink from './MarketInsightCtaLink'
import MarketInsightCtaHeading from './MarketInsightCtaHeading'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './MarketInsightCtaLinkList.scss'

const marketInsightCtaLinkListBlocks = {
    ctaHeadingBlock: 'MarketInsightCtaHeading',
    ctaLinkBlock: 'MarketInsightCtaLink',
};

const MarketInsightCtaLinkList = ({ items, className }) => (
    items && items.length > 0 ? (
        <div className={cn('market-insight-cta-link-list', {[className]: className})}>
            <div className='market-insight-cta-link-list__grid'>
                {items.map((item, index) => {
                    switch (item.componentName) {
                        case marketInsightCtaLinkListBlocks.ctaHeadingBlock:
                            return <MarketInsightCtaHeading key={index} {...item} />
                        case marketInsightCtaLinkListBlocks.ctaLinkBlock:
                            return (
                                <div className='market-insight-cta-link-list__item' key={index}>
                                    <MarketInsightCtaLink {...item}  />
                                </div>
                            )
                        default:
                            console.error(
                                `Components.${item.componentName} is not supported within MarketInsightCtaLinkList`
                            );
                            return null
                    }
                })}
            </div>
        </div>
    ) : null
);

MarketInsightCtaLinkList.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.exact(MarketInsightCtaLink.propTypes),
        PropTypes.exact(MarketInsightCtaHeading.propTypes)
    ]))
}

export default MarketInsightCtaLinkList;