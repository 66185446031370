import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './RichText.scss'

const RichText = ({ text, className, children }) => {
  const blockClassName = cn("rich-text", className);

  return (text || children) ? (
    <React.Fragment>
      {children ? <div
        className={blockClassName}
      >
        {children}
      </div> : text ? 
      <div
        className={blockClassName}
        dangerouslySetInnerHTML={{ __html: text }}
      /> : null}
    </React.Fragment>
  ) : null;
};

RichText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

RichText.propTypesMeta = 'exclude';

export default RichText;
