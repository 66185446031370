// extracted by mini-css-extract-plugin
var _1 = "LF46jkz53t2bF3XIs2Ix";
var _2 = "M7IBUsgZdE0sPUxRPGpJ";
var _3 = "odxD7ZrhGKNt7wljVsMD";
var _4 = "O5LGGVovkVV1VOYdUpok";
var _5 = "YGM3tlrxd0Qp2FUWrMwj";
var _6 = "kbtVdwVZvUL8dPddUY65";
var _7 = "JoGoD9obIsETa_7P8ivN";
var _8 = "pZNHKCgSq49DjcXA_3e3";
var _9 = "MdEKZxO5O6ntFRG_ZMEO";
export { _1 as "country", _2 as "countryReport", _3 as "details", _4 as "employees", _5 as "hide", _6 as "info", _7 as "intro", _8 as "map", _9 as "office" }
