import React from 'react'
import PropTypes from 'prop-types'

import EmployeeCard from 'components/EmployeeCard'
import FluidImage from 'components/FluidImage'

import EmployeeType from 'types/EmployeeType'

class EmployeeBlockLarge extends React.PureComponent {
    static propTypes = {
        employee: PropTypes.shape(EmployeeType).isRequired,
        labels: PropTypes.shape({
            contactMeOrMyColleagues: PropTypes.string.isRequired,
            contactLabel: PropTypes.string.isRequired,
            viewAllEmployees: PropTypes.string.isRequired,
        }).isRequired,
        image: PropTypes.shape({
            url: PropTypes.string
        }),
        url: PropTypes.string.isRequired,
    }

    render() {
        const {
            employee,
            labels,
            image,
            url,
        } = this.props

        return (
            <div className="employee-block-large">
                <div className="employee">
                    <p className="contact-text h3">{labels.contactMeOrMyColleagues}</p>
                    <EmployeeCard
                        {...employee}
                        negative={true}
                    />
                    <div className="actions">
                        <a
                            className="button small negative"
                            data-type="employee"
                            data-name={employee.name}
                            href={employee.url}
                        >{labels.contactLabel}</a>
                        {url && (
                            <a className="shortcut negative" href={url}>
                                {labels.viewAllEmployees}
                            </a>
                        )}
                    </div>
                </div>
                <FluidImage
                    className="image"
                    size={660}
                    {...image}
                />
            </div>
        )
    }
}

export default EmployeeBlockLarge
