import React from 'react'
import PropTypes from 'prop-types'
import './MarketInsightQAGroupBlock.scss'
import MarketInsightQABlock from 'components/MarketInsightQABlock'

const MarketInsightQAGroupBlock = ({ heading, qaBlocks }) => {
    return (
        <div className="market-insight-qa-group-block">
            {heading && (
                <h2 className="market-insight-qa-group-block__heading">
                    {heading}
                </h2>
            )}
            {qaBlocks && qaBlocks.length > 0 && (
                <ul className="market-insight-qa-group-block__list">
                    {qaBlocks.map((qa, index) => (
                        <li
                            key={index}
                            className="market-insight-qa-group-block__list-item"
                        >
                            <MarketInsightQABlock {...qa} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

MarketInsightQAGroupBlock.propTypes = {
    heading: PropTypes.string,
    qaBlocks: PropTypes.arrayOf(PropTypes.exact(MarketInsightQABlock.propTypes))
}

export default MarketInsightQAGroupBlock
