import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popup from 'components/Popup'
import ZoomButton from 'components/ZoomButton'

import './Lightbox.scss'

const Lightbox = ({ children }) => {
    const [zoomed, setZoomed] = useState(false);

    const toggleZoomed = () => {
        setZoomed(!zoomed)
    }

    return (
        <div className="lightbox">
            {children}
            <ZoomButton 
                className="lightbox__zoom-btn"
                onClick={toggleZoomed} 
            />
            <Popup 
                useCloseBtn={false} 
                isOpened={zoomed} 
                contentClassName="lightbox__popup-content-container"
                onClose={() => setZoomed(false)}
            >
                {children}
                <ZoomButton 
                    isActive
                    className="lightbox__zoom-btn lightbox__zoom-btn_in-popup"
                    onClick={toggleZoomed} 
                />
            </Popup>
        </div>
    )
}

Lightbox.propTypes = {
    children: PropTypes.node,
}

Lightbox.propTypesMeta = 'exclude'

export default Lightbox
