import React from 'react'
import Link from 'components/Link'
import LinkWithIcon from 'components/LinkWithIcon'
import PoorText from 'components/PoorText'
import PropTypes from 'prop-types'
import { replaceStringPlaceholders } from '../../js/string-helper'
import { MARKET_INSIGHT_STATISTICS_BLOCK_IDS } from '../../constants'

import './MarketInsightInteractiveReportsBlock.scss'

const MarketInsightInteractiveReportsBlock = ({
    heading,
    introHtml,
    sasReportLinks,
    loginLink
}) => {
    return (
        <div className='market-insight-interactive-reports-block' id={MARKET_INSIGHT_STATISTICS_BLOCK_IDS.interactiveReports}>
            <div className='market-insight-interactive-reports-block__container'>
                {heading && <h2 className='market-insight-interactive-reports-block__heading'>{heading}</h2>}
                {introHtml && (
                    <PoorText 
                        text={`${replaceStringPlaceholders(
                            introHtml,
                            loginLink ? `<a href=${loginLink.url}>${loginLink.text}</a>` : '',
                        )}`}
                        className='poor-text_in-market-insight-interactive-reports-block' 
                    />
                )}
                {sasReportLinks && sasReportLinks.length > 0 ? (
                    <div className='market-insight-interactive-reports-block__columns'>
                        {sasReportLinks.map((link, index) => (
                            <div key={index} className='market-insight-interactive-reports-block__item'>
                                <LinkWithIcon 
                                    {...link}
                                    className='market-insight-interactive-reports-block__link'
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

MarketInsightInteractiveReportsBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    sasReportLinks: PropTypes.arrayOf(PropTypes.exact(LinkWithIcon.propTypes)),
    loginLink: PropTypes.exact(Link.propTypes)
}

export default MarketInsightInteractiveReportsBlock