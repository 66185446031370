import React from 'react'
import PropTypes from 'prop-types'
import CountryPageLatestNews from './CountryPageLatestNews'
import LinkWithIcon from 'components/LinkWithIcon'
import './CountryPageLatestNewsBlock.scss'


const CountryPageLatestNewsBlock = ({ heading, news, lang, newsArchiveUrl}) => {
    return (
        <div className="country-page-latest-news-block">
                {heading && <h2 className="country-page-latest-news-block__heading">{heading}</h2>}
                {news && <ul className="country-page-latest-news-block__list">
                    {news.map((link, index) => (
                        <li className="country-page-latest-news-block__list-item" key={index}>
                            <CountryPageLatestNews 
                                {...link} 
                                pablishedLabel={lang.publishedLabel}  
                                headingTagLevel={heading ? 3 : 2}
                            />
                        </li>
                    ))}    
                </ul>}
                <LinkWithIcon text={lang.newsArchiveUrlLabel} url={newsArchiveUrl} className="country-page-latest-news-block__archive-link" />
        </div>
    )
}

CountryPageLatestNewsBlock.propTypes = {
    heading: PropTypes.string,
    news: PropTypes.arrayOf(PropTypes.exact(CountryPageLatestNews.propTypes)).isRequired,
    lang: PropTypes.shape({
        newsArchiveUrlLabel: PropTypes.string.isRequired,
        publishedLabel: PropTypes.string.isRequired,
    }).isRequired,
    newsArchiveUrl: PropTypes.string
}

export default CountryPageLatestNewsBlock
