/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import Icon from 'components/Icon'
import cn from 'classnames'
import './CountryPageSocialLink.scss'

const icons = {
    facebook: 'facebook',
    twitter: 'twitter',
    instagram: 'instagram'
}

const CountryPageSocialLink = ({className, openInNewTab, url, text, icon  }) => {
    return (
        <Link
            url={url}
            className={cn("country-page-social-link", className)}
            target={openInNewTab ? '_blank' : null}
        >
            {icon &&<Icon use={icon} className="country-page-social-link__icon" />}
            {text &&<span className="country-page-social-link__text">{text}</span>}
        </Link>
    )
}

CountryPageSocialLink.propTypes = Object.assign({}, Link.propTypes, {
    icon: PropTypes.oneOf(Object.values(icons)),
})

CountryPageSocialLink.icons = icons

export default CountryPageSocialLink
