import PropTypes from 'prop-types'

const AttachmentType = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string,
    fileSize: PropTypes.string,
    fileType: PropTypes.string,
}

export default AttachmentType
