import React from 'react';
import RichText from 'components/RichText'
import PropTypes from 'prop-types';
import cn from 'classnames';

import './InfoBlock.scss'

const InfoBlock = ({ heading, textHtml, className, inRichText = false }) => {
  return (
    <div className={cn("info-block", className, {
      'info-block_in-rich-text': inRichText
    })}>
      <div className='info-block__container'>
        {heading && <h2 className="info-block__heading">{heading}</h2>}
        {textHtml && <RichText text={textHtml} />}
      </div>
    </div>
  );
};

InfoBlock.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  className: PropTypes.string,
  inRichText: PropTypes.bool
};

export default InfoBlock;
