import React from 'react'
import PropTypes from 'prop-types'

import FilterList from 'hoc/FilterList'
import FilterTable from 'components/FilterTable'
import Report from 'components/Report'
import Species from 'components/Species'

import ReportType from 'types/ReportType'

class ReportList extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(ReportType)).isRequired,
        labels: PropTypes.shape({
            type: PropTypes.string.isRequired,
            species: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            contacts: PropTypes.string.isRequired,
            downloadReport: PropTypes.string.isRequired,
            contact: PropTypes.string.isRequired,
            resultsOnFormatLabel: PropTypes.string,
            resultsCountFormatLabel: PropTypes.string
        }).isRequired,
        listHeading: PropTypes.string,
        shown: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        onToggle: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired,
        searchValue: PropTypes.string
    }

    static defaultProps = {
        labels: {
            resultsCountFormatLabel: "{0} treff",
            resultsOnFormatLabel: "på {0}"
        }
    }

    render() {
        const { items, labels, shown, searchValue, listHeading, totalItems } = this.props

        return (
            <FilterTable
                component={Report}
                headers={[
                    labels.date,
                    labels.type,
                    labels.species,
                    labels.country,
                ]}
                items={items}
                labels={labels}
                name={item => item.title}
                onToggle={this.props.onToggle}
                rows={[
                    item => item.date,
                    item => item.type,
                    item => <Species species={item.species} />,
                    item => item.countries && item.countries.map(c => c.name).join(', '),
                ]}
                shown={shown}
                type="report"
                searchValue={searchValue}
                caption={listHeading}
                totalItems={totalItems}
            />
        )
    }
}

export default FilterList(ReportList)
