import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'
import moment from 'moment'

import 'moment/locale/en-gb';
import 'moment/locale/nb';

import FilterList from 'hoc/FilterList'
import ArticleCard from 'components/ArticleCard'
import NoResults from 'components/NoResults'

import * as css from './NewsFolder.scss'

// Selector
const newsItemsSelector = items => items

const monthSelector = createSelector(
    newsItemsSelector,
    items => groupBy(items, item => item.published.substr(0,7))
)

class NewsFolder extends React.Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        shown: PropTypes.arrayOf(PropTypes.string),
        onToggle: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired,
        locale: PropTypes.string.isRequired,
        labels: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)

        switch(props.locale) {
            case 'no':
                moment.locale('nb')
                break

            case 'en':
            case 'en-gb':
            default:
                moment.locale('en-gb')
                break
        }
    }

    _handleResetClick = e => {
        e.preventDefault()
        window.dispatchEvent(new Event('seafood-filter-reset'))
    }

    render() {
        const {
            items,
            labels
        } = this.props

        const months = monthSelector(items)

        return (
            <div aria-live="polite" className={css.container}>
                {items.length === 0 && (
                    <NoResults
                        labels={labels}
                        light
                        margin
                        onReset={this._handleResetClick}
                    />
                )}
                {Object.keys(months).map(month => (
                    <section className={cn('list-items')} key={month}>
                        <h2 className="h4">{moment(month, 'YYYY-MM').format('MMMM, Y')}</h2>
                        <div className={cn("list-items-content", {
                            "grouped": months[month].length >= 8
                        })}>
                            {months[month].map(i =>
                                <a
                                    className="list-item"
                                    href={i.url}
                                    key={i.id}
                                >
                                    <ArticleCard 
                                        {...i}
                                        headingTagLevel={month ? 3 : 2}
                                    />
                                </a>
                            )}
                        </div>
                    </section>
                ))}
            </div>
        )
    }
}

export default FilterList(NewsFolder)
