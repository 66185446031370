import PropTypes from 'prop-types'

import EmployeeType from 'types/EmployeeType'

const OfficeType = {
    address: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    formattedPhoneNumber: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    countryReport: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        openLinkInNewWindow: PropTypes.bool,
    }),
    employees: PropTypes.arrayOf(PropTypes.shape(EmployeeType)),
    country: PropTypes.string.isRequired,
    flag: PropTypes.string,
    intro: PropTypes.string,
    officePageLink: PropTypes.string.isRequired
}

export default OfficeType
