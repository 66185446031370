import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ReactSelectWrapper from 'components/ReactSelectWrapper'
import { format } from 'utils'
import * as css from './Pagination.scss'

import ReactSelect from 'react-select'

class Pagination extends React.Component {
    static propTypes = {
        page: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
        offset: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
        nextLabel: PropTypes.string.isRequired,
        prevLabel: PropTypes.string.isRequired,
        pageLabel: PropTypes.string.isRequired,
        getPage: PropTypes.func.isRequired,
        negative: PropTypes.bool.isRequired,
        query: PropTypes.string,
        filters: PropTypes.object,
    }

    static defaultProps = {
        negative: false,
        nextLabel: 'Next',
        prevLabel: 'Prev',
        pageLabel: '{0} of {1} pages'
    }

    _handleNextClick = e => {
        e.preventDefault()
        this.props.getPage(this.props.page + 1)
    }

    _handlePrevClick = e => {
        e.preventDefault()
        this.props.getPage(this.props.page - 1)
    }

    _handlePageChange = ({ value }) => this.props.getPage(value)

    _url = offset => {
        const {
            query,
            limit,
            filters
        } = this.props

        let url = []

        if (query) url.push('q=' + query)
        if (filters) {
            for (let key in filters) {
                url.push(key + '=' + filters[key])
            }
        }
        url.push('limit=' + limit)
        url.push('offset=' + offset)

        return `?` + url.join('&')
    }

    render() {
        const {
            negative,
            page,
            limit,
            totalItems,
            nextLabel,
            prevLabel,
            pageLabel,
        } = this.props

        if (totalItems == 0 || limit == 0) return null

        const pages = Math.ceil(totalItems / limit)

        const hasPrev = page > 1
        const hasNext = page < pages

        const pagesArray = []

        for (let i = 1; i <= pages; i++) {
            pagesArray.push(i)
        }
   
        const value = {
            value: page,
            label: format(pageLabel, [page, pages])
        }

        return (
            <div className={cn(css.pagination, {
                [css.negative]: negative,
                [css.hide]: pages === 1
            })}>
                <div className={css.page}>
                    <ReactSelectWrapper>
                        <ReactSelect
                            backspaceToRemoveMessage=""
                            name="pagination_select"
                            id="pagination_select_id"
                            className={!negative ? 'negative' : null}
                            clearable={false}
                            onChange={this._handlePageChange}
                            options={pagesArray.map(x => ({
                                value: x,
                                label: x
                            }))}
                            value={value}
                            classNamePrefix="Select"
                            aria-label="Pagination"
                        />
                    </ReactSelectWrapper> 
                </div>
                {hasPrev && (
                    <button
                        className={cn("button small", css.button, css.prev, {
                            [css.noNext]: !hasNext
                        })}
                        onClick={this._handlePrevClick}
                    >
                        <img className={cn(css.paginationIcon, css.reverse)} src="../../assets/arrow-right-white.png" alt='' />
                        <span>{prevLabel}</span>
                    </button>
                )}
                {hasNext && (
                    <button
                        className={cn("button small", css.button, css.next)}
                        onClick={this._handleNextClick}
                    >
                        <span>{nextLabel}</span>
                        <img className={css.paginationIcon} src="../../assets/arrow-right-white.png" alt='' />
                    </button>
                )}
            </div>
        )
    }
}

export default Pagination
