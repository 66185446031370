import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Loader'
import NoResults from 'components/NoResults'
import MarketInsightStatisticArchiveItem from './MarketInsightStatisticArchiveItem'
import api from '../../js/api-helper'
import './MarketInsightStatisticArchiveList.scss'

const MarketInsightStatisticArchiveList = ({
    loadItemsEndpoint,
    lang
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [archiveListItems, setArchiveListItems] = useState(null)

    const loadArchiveListItems = () => {
        setIsLoading(true);
        setError(null);
        api.get(loadItemsEndpoint)
            .then(response => {
                setIsLoading(false);
                if (response.success) {
                    setArchiveListItems(response.data.items);
                } else {
                    setError(response.errorMessage);
                }
            })
            .catch(() => {
                setError(lang.serverErrorText);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        loadArchiveListItems();
    }, []);

    return (
        <div
            className='market-insight-statistic-archive-list'
        >
            {archiveListItems && archiveListItems.length > 0 ? (
                    archiveListItems.map((item, index) => (
                        <div key={index} className='market-insight-statistic-archive-list__item'>
                            <MarketInsightStatisticArchiveItem
                                pdfLabel={lang.pdfLabel}
                                excelLabel={lang.excelLabel}
                                {...item} 
                            />
                        </div>
                    ))
            ) : null}
            {archiveListItems && archiveListItems.length === 0 && (
                <NoResults
                    labels={{
                        noResultsHeading: lang.noListResultsHeading,
                        noResultsText: lang.noListResultsText
                    }}
                    light
                />
            )}
            {isLoading && (
                <div className="market-insight-statistic-archive-list__loading">
                    <Loader />
                </div>
            )}
            {error && <div className='market-insight-statistic-archive-list__error'>{error}</div>}
        </div>
    )
}

MarketInsightStatisticArchiveList.propTypes = {
    loadItemsEndpoint: PropTypes.string.isRequired,
    lang: PropTypes.shape({
        pdfLabel: PropTypes.string.isRequired,
        excelLabel: PropTypes.string.isRequired,
        noListResultsHeading: PropTypes.string.isRequired,
        noListResultsText: PropTypes.string.isRequired,
        serverErrorText: PropTypes.string.isRequired,
    }).isRequired,
}

export default MarketInsightStatisticArchiveList
