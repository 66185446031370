import React from 'react'
import PropTypes from 'prop-types'
import './ImageCarouselBlock.scss'
import Photographer from 'components/Photographer'
import ResponsiveImage from 'components/ResponsiveImage'
import ImageWithPopup from 'components/ImageWithPopup'

const ImageCarouselBlockSlide = ({ image, photographer }) => {
    return (
        <div className="image-carousel-block__slide">
            <ImageWithPopup image={image} photographer={photographer} />
        </div>
    )
}

ImageCarouselBlockSlide.defaultProps = {}

ImageCarouselBlockSlide.propTypes = {
    image: PropTypes.exact(ResponsiveImage.propTypes),
    caption: PropTypes.string,
    photographer: PropTypes.exact(Photographer.propTypes),
}

export default ImageCarouselBlockSlide



