import React from 'react'
import PropTypes from 'prop-types'
import jquery from 'jquery'

import { search as trackSearch } from '../../tracking'

const emptySearchResult = {
    results: [],
    totalCount: 0,
    moreLink: null
}

export default ComposedComponent => class extends React.Component {
    static displayName = 'Search'

    static defaultProps = {
        limit: 10,
        showLoadMore: true,
        showResultHeaders: true,
        showSearchSummary: true
    }

    static propTypes = {
        classNames: PropTypes.shape({
            search: PropTypes.string,
            results: PropTypes.string
        }),
        limit: PropTypes.number.isRequired,
        showLoadMore: PropTypes.bool.isRequired,
        showResultHeaders: PropTypes.bool.isRequired,
        showSearchSummary: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        query: PropTypes.string
    }

    state = {
        employees: emptySearchResult,
        pages: emptySearchResult,
        error: null,
        searching: false,
        submittedSearchQuery: ''
    }

    _search = (query) => {
        const { language, url, limit } = this.props

        if(! query || query.length === 0) {
            return this._clearSearch()
        }

        if(! query || query.length < 3) return

        // TODO: Switch from when to a single request? Can't abort promises
        // if(this.request) this.request.abort()

        this.setState({ searching: true })

        this.request = jquery.when(
            jquery.get(`${url}/standard`, {
                q: query,
                limit,
                language
            }),
            jquery.get(`${url}/employees`, {
                q: query,
                limit,
                language
            })
        )
        .done((pages, employees) => {
            trackSearch(query, pages[0].totalCount + employees[0].totalCount)

            this.setState({
                error: false,
                submittedSearchQuery: query,
                searching: false,
                pages: { ...pages[0] },
                employees: { ...employees[0] }
            })
        })
        .fail((error) => {
            console.error(error)
            this.setState({
                submittedSearchQuery: '',
                searching: false,
                pages: emptySearchResult,
                employees: emptySearchResult,
                error: error.responseText || error.statusText,
            })
        })
    }

    _clearSearch = () => {
        this.setState({
            employees: {
                ...this.state.employees,
                results: []
            },
            pages: {
                ...this.state.pages,
                results: []
            }
        })
    }

    _loadMore = (type) => {
        switch(type) {
            case 'pages':
                return jquery.get(this.state.pages.moreLink)
                    .done(res => {
                        this.setState({
                            pages: {
                                moreLink: res.moreLink,
                                totalCount: res.totalCount,
                                results: this.state.pages.results.concat(res.results)
                            }
                        })
                    })
                    .fail(error => {
                        console.error(error)
                    })

            case 'employees':
                return jquery.get(this.state.employees.moreLink)
                    .done(res => {
                        this.setState({
                            employees: {
                                moreLink: res.moreLink,
                                totalCount: res.totalCount,
                                results: this.state.employees.results.concat(res.results)
                            }
                        })
                    })
                    .fail(error => {
                        console.error(error)
                    })

            default:
                return console.error('_loadMore: Unknown type', type)
        }
    }

    render() {
        return (
            <ComposedComponent
                {...this.props}
                {...this.state}
                loadMore={this._loadMore}
                search={this._search}
            />
        )
    }
}
