import PropTypes from 'prop-types';

const SelectFilterOption = {};

SelectFilterOption.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
  selected: PropTypes.bool,
};

export default SelectFilterOption;