import React from 'react'
import PropTypes from 'prop-types'
import './MarketInsightQABlock.scss'
import PoorText from 'components/PoorText'
import Icon from 'components/Icon'
import cn from 'classnames'
import { Collapse } from 'react-collapse';

const MarketInsightQABlock = ({ question, answerHtml }) => {
    const [isClosed, setIsClosed] = React.useState(true)

    const onQuestionClick = () => {
        setIsClosed(prevIsClosed => !prevIsClosed)
    }

    return (
        <div className="market-insight-qa-block">
            <button
                className={cn('market-insight-qa-block__question', {
                    'market-insight-qa-block__question_active': !isClosed
                })}
                onClick={onQuestionClick}
            >
                <span className="market-insight-qa-block__question-text">
                    {question}
                </span>
                <Icon
                    icon="caret"
                    className={cn('market-insight-qa-block__question-icon', {
                        'market-insight-qa-block__question-icon_active': !isClosed
                    })}
                />
            </button>
            <Collapse 
                isOpened={!isClosed}
            >
                <div className='market-insight-qa-block__answer'><PoorText text={answerHtml} /></div>
            </Collapse >
        </div>
    )
}

MarketInsightQABlock.propTypes = {
    question: PropTypes.string,
    answerHtml: PropTypes.string
}

export default MarketInsightQABlock
