import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Input = ({
    className,
    name,
    label,
    placeholder,
    value,
    type,
    onBlur,
    onChange,
    validation
}) => (
    <div className={cn("input-wrapper", className)}>
        {label && <label htmlFor={`order_${name}`}>{label}</label>}
        <input
            className={`${validation ? "error" : ""}`}
            id={`order_${name}`}
            type={type}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            value={value || ""}
        />
        {validation && <small className="input-validation">{validation}</small>}
    </div>
)

Input.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    validation: PropTypes.string,
}

Input.defaultProps = {
    type: 'text',
}

export default Input
