import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import * as css from './Filter.scss'

const Filter = ({ disabled, id, title, children }) => (
    <div className={cn(css.filter, { [css.disabled]: disabled })}>
        {title && <h4 className={css.title} id={id}>{title}</h4>}
        {children}
    </div>
)

Filter.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.node.isRequired
}

export default Filter
