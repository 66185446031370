import jquery from 'jquery'
import { trapFocus } from './trap-focus';
import { toggleScrollLock } from '../utils'

window.addEventListener('DOMContentLoaded', () => {
    const topMenuWrapper = jquery('.top-menu-wrapper')
    const primaryNav = jquery('.primary-navigation')
    const userMenu = jquery('.user-menu')
    const myPageToggler = jquery('.my-page-toggler')
    const pageContent = jquery('header, main, footer')

    function toggleUserMenu(e) {
        if(e && e.preventDefault) e.preventDefault()
        myPageToggler.toggleClass('open')
        topMenuWrapper.toggleClass('open')
        topMenuWrapper.toggleClass('user-menu')
        userMenu.toggleClass('open')
    }

    function toggleMenu() {
        if(userMenu.hasClass('open')) {
            toggleUserMenu()
        }

        topMenuWrapper.toggleClass('open')
        primaryNav.toggleClass('open')
        jquery('[data-toggle="menu"]').toggleClass('open')
        toggleScrollLock()

        const expanded = topMenuWrapper.hasClass('open');
        pageContent.attr('aria-hidden', expanded ? 'true' : null);
        
        if(expanded) {
            document.getElementById('menu').focus();
            trapFocus(primaryNav);
        } else {
            const button = document.querySelector('[data-toggle="menu"]')

            if(button) {
                button.focus()
            }
        }
    }

    topMenuWrapper.on('click', e => {
        if(e.target !== e.currentTarget) {
            return
        }

        if(userMenu.hasClass('open')) {
            toggleUserMenu()
        }
    })

    jquery('[data-toggle="menu"]').on('click', toggleMenu)

    window.addEventListener('keydown', e => {
        if(e.keyCode === 27 && primaryNav.is(':visible')) {
            toggleMenu()
        }

        if(e.keyCode === 27 && userMenu.is(':visible')) {
            toggleUserMenu()
        }
    })

    jquery('.menu-item-toggler').on('click', e => {
        const $a = jquery(e.currentTarget)
        $a.parent().toggleClass('expanded')
        $a.closest(".user-menu").toggleClass('expanded')
    })

    myPageToggler.on('click', toggleUserMenu)
});
