import 'react-dates/initialize';
import React, { useState, useLayoutEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css'
import './SelectFilter.scss';

const SelectFilterDateRange = ({ 
	heading,
	fromPlaceholder,
	toPlaceholder,
	from, 
	to,
	onChange
}) => {
	const [options, setOptions] = useState({
		withPortal: true,
		focusedInput: null,
		numberOfMonths: 2,
		orientation: 'horizontal'
	})

	const { withPortal, numberOfMonths, orientation, focusedInput } = options;

	useLayoutEffect(() => {
		_onResize();
		const _throttledOnResize = throttle(_onResize, 200)
		window.addEventListener('resize', _throttledOnResize)

		return () => window.removeEventListener('resize', _throttledOnResize);
	}, []);

	const _onResize = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        let state = {}

        // Use fullscreen datepicker only smaller screens
        if (width >= 890 && withPortal) {
            state.withPortal = false
        } else if (width < 890 && !withPortal) {
            state.withPortal = true
        }

        if (width >= 750 && numberOfMonths === 1) {
            state.numberOfMonths = 2
        } else if (width < 750 && numberOfMonths === 2) {
            state.numberOfMonths = 1
        }

        // Only update the state if we have made any modifications
        if (Object.keys(state).length) {
			setOptions(prev => ({ ...prev, ...state }))
		}
    }

	const onFocusChange = focusedInput => {
		const inputs = document.querySelectorAll('.DateInput__input')
 
        Array.from(inputs)
            .forEach(input => {
                input && input.blur()
            })

		setOptions(prev => ({...prev, focusedInput}))
	}

	return (
		<div className='select-filter'>
			{heading && <label className='select-filter__label' htmlFor='startDateId'>{heading}</label>}
			<DateRangePicker
				anchorDirection="left"
				endDate={to ? moment(to) : undefined}
				endDateId="endDateId"
				endDatePlaceholderText={toPlaceholder}
				focusedInput={focusedInput}
				isOutsideRange={() => false}
				numberOfMonths={numberOfMonths}
				onDatesChange={onChange}
				onFocusChange={onFocusChange}
				orientation={orientation}
				showClearDates={true}
				startDate={from ? moment(from) : undefined}
				startDateId="startDateId"
				startDatePlaceholderText={fromPlaceholder}
				withPortal={withPortal}
				regular={true}
			/>
		</div>
	)
};

SelectFilterDateRange.propTypes = {
	heading: PropTypes.string,
	from: PropTypes.string,
	to: PropTypes.string,
	fromName: PropTypes.string.isRequired,
	toName: PropTypes.string.isRequired,
	fromPlaceholder: PropTypes.string.isRequired,
	toPlaceholder: PropTypes.string.isRequired,
	onChange: PropTypes.func
};

SelectFilterDateRange.propTypesMeta = {
	onChange: 'exclude'
};

export default SelectFilterDateRange;
