import jQuery from 'jquery'

export function initMyPages() {
    const myPage = document.querySelector('.my-page')
    const buttons = myPage.querySelectorAll('button[role="tab"]')
    const forms = myPage.querySelectorAll('.accessibility-form')

    function handleClick(e) {
        const button = e.currentTarget

        if (button.className.indexOf('active') === -1) {
            const activeButton = button.parentElement.querySelector('button.active[role="tab"]')

            activeButton.classList.remove('active')
            button.classList.add('active')

            const activeTab = myPage.querySelector(`[role="tabpanel"][data-tab="${activeButton.dataset.tab}"]`)
            jQuery(activeTab).animate({ opacity: .25 }, 200, () => {
                activeTab.removeAttribute('style')
                activeTab.classList.remove('active')

                const tab = myPage.querySelector(`[role="tabpanel"][data-tab="${button.dataset.tab}"]`)
                jQuery(tab).show().animate({ opacity: 1 }, 200, () => {
                    tab.removeAttribute('style')
                    tab.classList.add('active')
                })
            })
        }
    }

    function enhanceFormAccessibility(formContainer) {
        if (!formContainer) return;
    
        const controls = formContainer.querySelectorAll(
            'input:not([type=hidden]):not([type=submit]):not([type=button]):not([type=reset]), textarea, select'
        );
    
        controls.forEach((control) => {
            const name = control.getAttribute('name');
            if (!name) return;
    
            const errorElement = document.querySelector(`.field-validation-error[data-valmsg-for="${name}"]`);
            if (!errorElement) return;
    
            const errorId = `${name}-error`;
            control.setAttribute('aria-describedby', errorId);
            errorElement.setAttribute('id', errorId);
        });
    }

    for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i]
        button.addEventListener('click', handleClick)
    }

    for (let i = 0; i < forms.length; i++) {
        const form = forms[i]
        enhanceFormAccessibility(form)
    }
}

window.addEventListener('DOMContentLoaded', () => {
    const myPage = document.querySelector('.my-page')

    if (!myPage) return

    initMyPages()
})
