import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import EventType from 'types/EventType'

import * as css from './EventCard.scss'

const Card = ({
    className,
    id,
    url,
    children,
    onClick
}) => {
    if(url) {
        return <a
            className={className}
            data-id={id}
            href={url}
            onClick={onClick}
            >{children}</a>
    } else {
        return <div className={className} onClick={onClick}>{children}</div>
    }
}

Card.propTypes = {
    className: PropTypes.string,
    id: PropTypes.number.isRequired,
    url: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func
}

const EventCard = ({
    city,
    country,
    endDateShortFormatted,
    id,
    linkUrl,
    onClick,
    title,
    startDate,
    startDateShortFormatted,
    isExpired,
}) => (
    <Card
        className={cn(css.card, {
            [css.expired]: isExpired
        })}
        id={id}
        onClick={onClick}
        url={linkUrl}
    >
        <header>
            <h2>{isExpired ? <span className={css.expiredTitle}>{title}</span> : title}</h2>
        </header>
        <div className={cn(css.meta, 'typo-small')}>
            {startDate && (
                <p className={css.date}>
                    {startDateShortFormatted != endDateShortFormatted
                        ? [startDateShortFormatted, endDateShortFormatted].join(' - ')
                        : startDateShortFormatted
                    }
                </p>
            )}
            <p>{country ? [city, country].join(', ') : city}</p>
        </div>
    </Card>
)

EventCard.propTypes = {
    ...EventType,
    onClick: PropTypes.func
}

EventCard.defaultProps = {
    isExpired: false
}

export default EventCard
