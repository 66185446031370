import React from 'react'
import TypedLink from 'components/Models/TypedLink'
import PoorText from 'components/PoorText'
import LinkWithIcon from 'components/LinkWithIcon'
import PropTypes from 'prop-types'

import './MarketInsightLinkWithDescriptionBlock.scss'

const MarketInsightLinkWithDescriptionBlock = ({
    heading,
    introHtml,
    link
}) => {
    return (
        <div className='market-insight-link-with-description-block'>
            {heading && <h4 className='market-insight-link-with-description-block__heading'>{heading}</h4>}
            {introHtml && <PoorText text={introHtml} className='market-insight-link-with-description-block__intro poor-text_in-market-insight-link-with-description-block' />}
            {link && 
                <div className='market-insight-link-with-description-block__link-holder'>
                    <LinkWithIcon
                        {...link}
                        className='market-insight-link-with-description-block__link link-with-icon_in-market-insight-link-with-description-block'
                    />
                </div>
            }
        </div>
    )
}

MarketInsightLinkWithDescriptionBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    link: PropTypes.exact(TypedLink.propTypes)
}

export default MarketInsightLinkWithDescriptionBlock