import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './MarketInsightStatisticEventsList.scss'
import MarketInsightCalendarDate from 'components/MarketInsightCalendarDate'
import api from '../../js/api-helper'
import formHelper from 'js/form-helper'
import replaceQueryParameters from '@creuna/utils/replace-query-parameters'
import cn from 'classnames'
import { DateBoxViews } from 'components/DateBox/DateBoxViews'
import Pager from 'components/Pager'
import Loader from 'components/Loader'
import NoResults from 'components/NoResults'

const MarketInsightStatisticEventList = ({
    loadItemsEndpoint,
    lang
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = React.useState(null)
    const [pager, setPager] = useState(null)
    const [calendarDates, setCalendarDates] = React.useState(null)
    const onPageChange = page => {
        loadCalendarDates(page.activePageNumber)
    }

    const loadCalendarDates = pageNumber => {
        setIsLoading(true)
        setError(null)
        let query = Object.assign({}, { page: pageNumber })
        formHelper.updatePageQueryString(query)
        var loadUrl = replaceQueryParameters(loadItemsEndpoint, query)
        api.execute(loadUrl)
            .then(response => {
                setIsLoading(false)
                if (response.success) {
                    setCalendarDates(response.data.calendarDates)
                    setPager(response.data.pager)
                } else {
                    setError(response.errorMessage)
                }
            })
            .catch(() => {
                setError(lang.serverErrorText)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const queryParams = formHelper.getQueryParams()
        const initPageNumber =
            queryParams && queryParams.page ? queryParams.page : 1

        loadCalendarDates(initPageNumber)
    }, [])

    return (
        <div
            className={cn('market-insight-statistic-events-list', {
                'market-insight-statistic-events-list_loading': isLoading
            })}
        >
            {calendarDates && calendarDates.length > 0 && (
                <div className="market-insight-statistic-events-list__items">
                    {calendarDates &&
                        calendarDates.length > 0 &&
                        calendarDates.map(calendarDate => {
                            return (
                                <div
                                    className="market-insight-statistic-events-list__item"
                                    key={calendarDate.date}
                                >
                                    <MarketInsightCalendarDate
                                        {...calendarDate}
                                        view={DateBoxViews.square}
                                        className="market-insight-calendar-date_in-calendar-list-page"
                                    />
                                </div>
                            )
                        })}
                </div>
            )}
            {calendarDates && calendarDates.length === 0 && (
                <NoResults
                    labels={{
                        noResultsHeading: lang.noListResultsHeading,
                    }}
                    light
                />
            )}
            {isLoading && (
                <div className="market-insight-statistic-events-list__loading">
                    <Loader />
                </div>
            )}
            {error && <div className='market-insight-statistic-events-list__error'>{error}</div>}
            {pager && <Pager {...pager} onPageChanging={onPageChange} />}
        </div>
    )
}

MarketInsightStatisticEventList.propTypes = {
    loadItemsEndpoint: PropTypes.string.isRequired,
    lang: PropTypes.shape({
        noListResultsHeading: PropTypes.string.isRequired,
        serverErrorText: PropTypes.string.isRequired,
    }).isRequired,
}

export default MarketInsightStatisticEventList
