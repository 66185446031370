/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FluidImage from 'components/FluidImage'
import Icon from 'components/Icon'

import * as css from './SearchResult.scss'

const PAGETYPES = {
    EMPLOYEE: 'EmployeePage'
}

const SearchResult = ({ 
    url,
    title,
    image,
    intro,
    pageType,
    isNewsPage,
    categories,
    publishedFormatted,
    hasAccess,
    hasAccessLabel,
}) => (
    <a className={cn(css.result, {
        [css.employee]: pageType === PAGETYPES.EMPLOYEE,
        [css.news]: isNewsPage
    })} href={url}>
        <header className={css.header}>
            <h2>{!hasAccess && <Icon use="key" />}{title}</h2>
            <div className={css.intro} dangerouslySetInnerHTML={{__html: intro}} />
            <div className={cn(css.meta, 'typo-small')}>
                <time>{publishedFormatted}</time>
                {categories && <span className={css.categories}>{categories}</span>}
            </div>
        </header>
        <div className={css.image}>
            <FluidImage
                fallback="../../assets/img/image-placeholder.svg"
                size={180}
                {...image}
            />
            {!hasAccess && (
                <div className={css['no-access']}>
                    <Icon use="key" />
                    <span dangerouslySetInnerHTML={{__html: hasAccessLabel}} />
                </div>
            )}
        </div>
    </a>
)

SearchResult.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.shape({
        url: PropTypes.string,
        focusPoint: PropTypes.object,
        imageTone: PropTypes.string
    }).isRequired,
    intro: PropTypes.string,
    date: PropTypes.string,
    hasAccess: PropTypes.bool.isRequired,
    hasAccessLabel: PropTypes.string.isRequired,
    isNewsPage: PropTypes.bool.isRequired,
    publishedFormatted: PropTypes.string.isRequired,
    categories: PropTypes.string,
    pageType: PropTypes.oneOf(['EmployeePage', 'AdvancedArticlePage', 'ArticlePage'])
}

export default SearchResult
