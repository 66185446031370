import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import PublishedDate from 'components/PublishedDate'
import './CountryPageLatestNewsBlock.scss'

const CountryPageLatestNews = ({ 
    image, 
    heading, 
    publishedDate,
    publishedDateFormatted,
    pablishedLabel, 
    url, 
    headingTagLevel
  }) => {
    const HeadingTag = `h${headingTagLevel}`;
    return (
        <a href={url} className="country-page-latest-news-block__item">
            {image && <ResponsiveImage {...image} className="responsive-image_size_country-page-latest-news-block" />}
            {heading &&
                <HeadingTag className="country-page-latest-news-block__item-heading">
                    {heading}
                </HeadingTag>
            }
            {publishedDate &&
                <PublishedDate
                    label={pablishedLabel}
                    date={publishedDate}
                    dateFormatted={publishedDateFormatted}
                    className="country-page-latest-news-block__published-date"
                />
            }
        </a>
    )
}

CountryPageLatestNews.propTypes = {
    image: PropTypes.exact(ResponsiveImage.propTypes),
    heading: PropTypes.string,
    publishedDate: PropTypes.string,
    publishedDateFormatted: PropTypes.string,
    pablishedLabel: PropTypes.string,
    url: PropTypes.string.isRequired,
    headingTagLevel: PropTypes.oneOf([2, 3]),
}

CountryPageLatestNews.propTypesMeta = {
    pablishedLabel: 'exclude',
    headingTagLevel: 'exclude',
}

export default CountryPageLatestNews
