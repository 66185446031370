import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import EmployeeCardList from 'components/EmployeeCardList'
import FilterTableItem from 'components/FilterTableItem'

import ReportType from 'types/ReportType'

import * as css from './Report.scss'

const Report = ({
    title,
    image,
    intro,
    attachment,
    employees,
    labels,
    species,
    hide,
    id
}) => (
    <FilterTableItem
        colSpan={5}
        hide={hide}
        id={id}
        name={title}
        type="report"
    >
        {image && image.url && <img className={css.image} src={image.url.downFit(360)} alt={image.alt ? image.alt : ''} />}
        <h2 className={cn(css.title, 'h1')}>{title}</h2>
        {species && (
            <div className={css.species}>
                <h4>{labels.species}</h4>
                {species.map(s => (
                    <span className="tag-label" key={s.id}>{s.name}</span>
                ))}
            </div>
        )}
        <div
            className={cn(css.intro, 'wysiwyg negative')}
            dangerouslySetInnerHTML={{ __html: intro }}
        />
        {!!employees.length && (
            <EmployeeCardList
                employees={employees}
                negative={true}
                title={labels.contacts}
            />
        )}
        {attachment && attachment.url && (
            <div className={css.attachment}>
                <a
                    className="button negative"
                    href={attachment.url}
                >{labels.downloadReport}</a>
                {employees[0] && employees[0].email && (
                    <a
                        className="button secondary negative"
                        href={`mailto:${employees[0].email}`}
                    >{labels.contact}</a>
                )}
            </div>
        )}
    </FilterTableItem>
)

Report.propTypes = {
    ...ReportType,
    hide: PropTypes.bool.isRequired
}

export default Report
