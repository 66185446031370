import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import CustomsQuotasTermsPopup from 'components/CustomsQuotasTermsPopup'
import './CustomsQuotasViewTermsAndConditions.scss'

const CustomsQuotasViewTermsAndConditions = ({
    text,
    linkText,
    termsPopup
}) => {
    const termsPopupRef = useRef(null)

    const openPopup = () => {
        if (termsPopupRef && termsPopupRef.current) {
            termsPopupRef && termsPopupRef.current.openPopup()
        }
    }

    return (
        <React.Fragment>
            <p className="customs-quotas-view-terms-and-conditions">
                {text} <button onClick={openPopup} className="customs-quotas-view-terms-and-conditions__button">{linkText}</button>
            </p>
            {termsPopup && (
                <CustomsQuotasTermsPopup 
                    {...termsPopup} 
                    ref={termsPopupRef}
                    isOpenedByDefault={false}
                    readOnlyMode={true} 
                />
            )}
        </React.Fragment>
    )
}

CustomsQuotasViewTermsAndConditions.propTypes = {
    text: PropTypes.string,
    linkText: PropTypes.string,
    termsPopup: PropTypes.exact(CustomsQuotasTermsPopup.propTypes)
}

export default CustomsQuotasViewTermsAndConditions
