import React from 'react';
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from 'components/Icon'

import * as css from './FilterTableRow.scss'

const FilterTableRow = ({
    id,
    hidden,
    onToggle,
    rows,
    name,
    className,
    type,
}) => {
    const onClick = () => onToggle(id);

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onClick();
        }
    };
    

    return (
        <tr
            className={cn(css.row, className, "filter-table-row", {
                [css.open]: !hidden,
            })}
            data-id={id}
            data-name={name || rows[0]}
            data-type={type}
            onClick={onClick}
            onKeyDown={onKeyDown}
            aria-controls={type && `${type + id}`}
            aria-expanded={!hidden}
            tabIndex={0}
        >
            {rows && rows.map((r, i) => {
                const isLastCell = i === rows.length - 1;

                return (
                <td
                    key={i + r}
                    className={cn({
                        [css.TdWithIcon]: isLastCell
                    })}
                >
                    {r}
                    {isLastCell && (
                        <Icon className={cn(css.toggleIcon)} icon="caret" size={16} />
                    )}
                </td>
                );
            })}
        </tr>
    )
}

FilterTableRow.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    hidden: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
}

export default FilterTableRow
