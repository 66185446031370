import React from 'react'
import PoorText from 'components/PoorText'
import MarketInsightCtaLinkList from 'components/MarketInsightCtaLinkList'
import MarketInsightLinksBlockListColumn from './MarketInsightLinksBlockListColumn.jsx'
import PropTypes from 'prop-types'

import './MarketInsightLinksBlock.scss'

const MarketInsightLinksBlock = ({
    heading,
    introHtml,
    ctaItems,
    linkListColumns
}) => {
    return (
        <div className='market-insight-links-block'>
            <div className='market-insight-links-block__container'>
                {heading && <h2 className='market-insight-links-block__heading'>{heading}</h2>}
                {introHtml && <PoorText text={introHtml} className='poor-text_in-market-insight-links-block' />}
                <div className='market-insight-links-block__content'>
                    {ctaItems && <MarketInsightCtaLinkList {...ctaItems} className='market-insight-links-block__cta-links' />}
                    {linkListColumns && linkListColumns.length > 0 ? (
                        <div className='market-insight-links-block__columns'>
                            {linkListColumns.map((column, index) => (
                                <MarketInsightLinksBlockListColumn {...column} key={index} />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

MarketInsightLinksBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    ctaItems: PropTypes.exact(MarketInsightCtaLinkList.propTypes),
    linkListColumns: PropTypes.arrayOf(PropTypes.exact(MarketInsightLinksBlockListColumn.propTypes))
}

export default MarketInsightLinksBlock