import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import EmployeeType from 'types/EmployeeType'
import FluidImage from 'components/FluidImage'
import Modal, { modalType } from 'components/Modal'
import LatestChroniclesBlock from 'components/LatestChroniclesBlock'

import * as css from './EmployeeModal.scss'

class EmployeeModal extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        ...EmployeeType
    }
    constructor() {
        super()

        this.state = {
            textExpandedInMobile: false
        }

        this._expandTextInMobile = this._expandTextInMobile.bind(this)
    }

    _expandTextInMobile() {
        this.setState({
            textExpandedInMobile: true
        })
    }

    render() {
        const {
            image,
            name,
            title,
            intro,
            phone,
            phoneFormatted,
            secondaryPhone,
            secondaryPhoneFormatted,
            email,
            modalLabels,
            onClose,
            latestChronicles
        } = this.props

        const { textExpandedInMobile } = this.state

        return (
            <Modal
                hasImage={image.url && !!image.url}
                labels={modalLabels}
                onClose={onClose}
                type={modalType.employee}
            >
                {image &&
                    image.url && (
                        <div className={css.imageContainer}>
                            <FluidImage
                                {...image}
                                className={css.image}
                                size={680}
                            />
                            <a
                                className={cn(
                                    'typo-small',
                                    css.downloadImageLink
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={image.url}
                            >
                                {modalLabels.downloadImage}
                            </a>
                        </div>
                    )}
                <div
                    className={cn(css.content, {
                        [css.expandedInMobile]: textExpandedInMobile
                    })}
                >
                    <div>
                        <header>
                            <h2 className="h1">{name}</h2>
                        </header>
                        <h2>{title}</h2>
                        <div
                            className={cn(css.intro, 'wysiwyg')}
                            dangerouslySetInnerHTML={{ __html: intro }}
                        />
                    </div>
                    <div className={css.contacts}>
                        {email && (
                            <div className={css.contact}>
                                <h3 className={css.contactLabel}>
                                    {modalLabels.email}
                                </h3>
                                <a
                                    href={`mailto:${email}`}
                                    className="link typo-small"
                                >
                                    {email}
                                </a>
                            </div>
                        )}
                        {phone && (
                            <div className={css.contact}>
                                <h3 className={css.contactLabel}>
                                    {modalLabels.phone}
                                </h3>
                                {phone && (
                                    <div>
                                        <a
                                            href={`tel:${phoneFormatted}`}
                                            className="link typo-small"
                                        >
                                            {phone}
                                        </a>
                                    </div>
                                )}
                                {secondaryPhone && (
                                    <div>
                                        <a
                                            href={`tel:${secondaryPhoneFormatted}`}
                                            className="link typo-small"
                                        >
                                            {secondaryPhone}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <LatestChroniclesBlock {...latestChronicles} theme="short-view" />
                    </div>
                    {!textExpandedInMobile && (
                        <div className={css.expandButtonContainer}>
                            <button
                                className={cn(
                                    'button secondary small',
                                    css.expandButton
                                )}
                                onClick={this._expandTextInMobile}
                            >
                                {modalLabels.showMore}
                            </button>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}

export default EmployeeModal
