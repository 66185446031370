import React from 'react'
import PropTypes from 'prop-types'
import { LabelsContext } from 'context/labels-context';

const getLabel = (label, labels, fallback) => {
    if(! label || ! labels) return fallback

    return labels[label] || fallback
}

const Label = ({
    fallback,
    label,
}) => {
    const labels = React.useContext(LabelsContext);

    return (
        <span>
            {getLabel(label, labels, fallback)}
        </span>
    )
}

Label.propTypes = {
    fallback: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

export default Label
