import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { DateBoxViews } from './DateBoxViews';
import { DatePeriod } from '../../constants';
import './DateBox.scss'

const DateBox = ({
    date,
    dateFormatted,
    datePeriod,
    view = DateBoxViews.rounded
}) => (
    <div className={cn('date-box', {
        [`date-box_${datePeriod}`]: datePeriod,
        [`date-box_view-${view}`]: view
    })}>
        <time className='date-box__text' dateTime={date} title={dateFormatted}>{dateFormatted}</time>
    </div>
)

DateBox.propTypes = {
    date: PropTypes.string.isRequired,
    dateFormatted: PropTypes.string,
    datePeriod: PropTypes.oneOf(Object.values(DatePeriod)),
    view: PropTypes.oneOf(Object.values(DateBoxViews))
}

DateBox.propTypesMeta = {
    view: 'exclude'
}

export default DateBox