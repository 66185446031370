import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import './ParallaxEffectBlock.scss'
import useEvent from 'js/use-event'
import $ from 'jquery'
import throttle from 'lodash/throttle'

const ParallaxEffectBlock = ({ blocks }) => {
    const block = useRef()
    const refs = useRef(blocks ? blocks.map(() => React.createRef()) : [])

    const checkIfCurrentBlockInFullView = () => {
        const topPosition = block.current.getBoundingClientRect().top
        const bottomPosition = block.current.getBoundingClientRect().bottom
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight
        return topPosition <= 0 && bottomPosition >= windowHeight
    }

    const checkIfItemInHalfViewInTop = item => {
        const topPosition = item.getBoundingClientRect().top
        const bottomPosition = item.getBoundingClientRect().bottom
        const itemHeight = bottomPosition - topPosition
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight

        return topPosition < windowHeight - itemHeight / 2 ? true : false
    }

    const checkIfItemInHalfViewInBottom = item => {
        const topPosition = item.getBoundingClientRect().top
        const bottomPosition = item.getBoundingClientRect().bottom
        const itemHeight = bottomPosition - topPosition
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight

        return topPosition >= 0 &&
            bottomPosition <= windowHeight + itemHeight / 2
            ? true
            : false
    }

    const checkIfItemInPartiallyView = item => {
        const topPosition = item.getBoundingClientRect().top
        const bottomPosition = item.getBoundingClientRect().bottom
        const itemHeight = bottomPosition - topPosition
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight

        return (topPosition >= 0 &&
            bottomPosition < windowHeight + itemHeight) ||
            (topPosition < 0 && bottomPosition > windowHeight - itemHeight)
            ? true
            : false
    }

    const checkIfItemUpToView = item => {
        const topPosition = item.getBoundingClientRect().top
        const bottomPosition = item.getBoundingClientRect().bottom
        const itemHeight = bottomPosition - topPosition
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight

        return (topPosition < 0 &&
            bottomPosition < windowHeight + itemHeight / 2)
            ? true
            : false
    }


    const onScroll = scrollingDown => {
        const animationDuration = 500
        const fixedMediaClassName =
            'parallax-effect-block__block-media_fixed'

        for (var i = 0; i < refs.current.length; i++) {
            const $item = refs.current[i].current
            const $itemMedia = $($item).find(
                '.parallax-effect-block__block-media'
            )

            const setItemMediaDefaultStyles = () => {
                $($itemMedia)
                    .removeClass(fixedMediaClassName)
                    .removeAttr('style')
            }

            if (
                checkIfCurrentBlockInFullView() &&
                checkIfItemInPartiallyView($item)
            ) {
                $($itemMedia).addClass(fixedMediaClassName)

                if (scrollingDown) {
                    if (checkIfItemUpToView($item)) {
                        $($itemMedia).show()
                        
                    } else if (checkIfItemInHalfViewInBottom($item)) {
                        $($itemMedia).fadeIn(animationDuration)
                    }
                }else{
                    if (checkIfItemInHalfViewInTop($item)) {
                        $($itemMedia).show()
                    } else {
                        $($itemMedia).fadeOut(animationDuration)
                    }
                }
                
            } else {
                setItemMediaDefaultStyles()
            }
        }
    }

    const throttledScrollHandler = useMemo(
        () => throttle(onScroll, 1),
        []
    )

    let lastScrollY = 0

    useEvent('scroll', () => {
        let newScrollY = window.scrollY
        throttledScrollHandler(newScrollY > lastScrollY)
        lastScrollY = newScrollY
    })

    useEvent('resize', () => {
        throttledScrollHandler(true)
    })

    return (
        <div className="parallax-effect-block" ref={block} >
            {blocks &&
                blocks.map((block, index) => {
                    const Block = (typeof window != 'undefined' ? window : global).Components[block.componentName]

                    if (!Block) {
                        console.error(
                            `Components.${block.componentName} is not defined`
                        )
                        return null
                    }
                    return (
                        <div
                            key={index}
                            className="parallax-effect-block__item"
                            ref={refs.current[index]}
                        >
                            <Block
                                {...block}
                                mediaClassName="parallax-effect-block__block-media"
                            />
                        </div>
                    )
                })}
        </div>
    )
}

ParallaxEffectBlock.propTypes = {
    blocks: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                componentName: PropTypes.oneOf(['HeroBlock', 'FullPageQuotationBlock', 'FullPageImageBlock'])
            })
        ])
    )
}

export default ParallaxEffectBlock
