import React, { forwardRef, useImperativeHandle  } from 'react'
import PropTypes from 'prop-types'
import CancelButton from 'components/CancelButton'
import PoorText from 'components/PoorText'
import RichText from 'components/RichText'
import ContentPopup from 'components/ContentPopup'
import './CustomsQuotasTermsPopup.scss'

// eslint-disable-next-line react/display-name
const CustomsQuotasTermsPopup = forwardRef(({
    heading,
    lastUpdatedDate,
    lastUpdatedDateFormatted,
    introHtml,
    textHtml,
    agreeToTermsUrl,
    readOnlyMode,
    isOpenedByDefault = true,
    lang
}, ref) => {
    const [isOpened, setIsOpened] = React.useState(isOpenedByDefault)

    useImperativeHandle(ref, () => ({
        openPopup() {
            setIsOpened(true)
        }
    }));

    const closePopup = () => {
        setIsOpened(false)
    }

    return (
        <ContentPopup
            isOpened={isOpened}
            className="customs-quotas-terms-popup"
            contentClassName="customs-quotas-terms-popup__content"
            onClose={closePopup}
        >
            {heading && <h2 className='customs-quotas-terms-popup__heading'>{heading}</h2>}
            {lastUpdatedDate && <time className='customs-quotas-terms-popup__last-updated' dateTime={lastUpdatedDate}>{lastUpdatedDateFormatted}</time>}
            {introHtml && <PoorText text={introHtml} className='customs-quotas-terms-popup__intro poor-text_in-customs-quotas-terms-popup' />}
            {textHtml && <RichText className='rich-text_in-customs-quotas-terms-popup' text={textHtml} />}
            {!readOnlyMode && (
                <div className='customs-quotas-terms-popup__actions'>
                {agreeToTermsUrl && 
                        <form method='post' action={agreeToTermsUrl}>
                            <button type='submit' className='link-button'>{lang.agreeToTermsLabel}</button>
                        </form>
                    }
                    <CancelButton 
                        text={lang.cancelButtonLabel}
                        onClick={() => setIsOpened(false)}
                    />
                </div>
            )}
        </ContentPopup>
    )
})

CustomsQuotasTermsPopup.propTypes = {
    heading: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    lastUpdatedDateFormatted: PropTypes.string,
    introHtml: PropTypes.string,
    textHtml: PropTypes.string,
    agreeToTermsUrl: PropTypes.string,
    readOnlyMode: PropTypes.bool,
    isOpenedByDefault: PropTypes.bool,
    lang: PropTypes.shape({
        agreeToTermsLabel: PropTypes.string.isRequired,
        cancelButtonLabel: PropTypes.string.isRequired,
        closeButtonLabel: PropTypes.string.isRequired,
    }).isRequired,
}

export default CustomsQuotasTermsPopup
