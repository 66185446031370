import React from 'react'
import PropTypes from 'prop-types'
import CountryPageSocialLink from 'components/CountryPageSocialLink'
import CountryPageEmployee from 'components/CountryPageEmployee'
import RichText from 'components/RichText'
import './CountryPageContactUsBlock.scss'

const CountryPageContactUsBlock = ({ heading, introHtml, addressHtml, phone, socialMediaLinks, employees, lang  }) => {
    return (
        <div className="country-page-contact-us-block">
            <div className="country-page-contact-us-block__container">
                {heading && <h2 className="country-page-contact-us-block__heading">{heading}</h2>}
                {introHtml &&<div className="country-page-contact-us-block__intro"><RichText text={introHtml} className="rich-text_in-country-page-contact-us-block"  /></div>}

                <div className="country-page-contact-us-block__column">
                    {addressHtml && 
                    <RichText 
                        text={addressHtml} 
                        className="country-page-contact-us-block__address rich-text_in-country-page-contact-us-block-address" 
                    />}
                    {phone && <a className='country-page-contact-us-block__phone' href={`tel:${phone}`}>{phone}</a>}
                    {socialMediaLinks && socialMediaLinks.length > 0 &&
                        <div className="country-page-contact-us-block__social-media">
                            <h3 className="country-page-contact-us-block__social-media-heading">{lang.followUsInSocialMediaHeading}</h3>
                            <ul className="country-page-contact-us-block__social-media-list">
                                {socialMediaLinks.map((link, index) => (
                                    <li key={index} className="country-page-contact-us-block__social-media-list-item">
                                    <CountryPageSocialLink {...link} /> 
                                    </li>
                                ))}  
                            </ul>
                        </div>
                    }
                    
                </div>
                
                <div className="country-page-contact-us-block__column">
                    {employees && <ul className="country-page-contact-us-block__employee-list">
                        {employees.map((employee, index) => (
                            <li key={index}  className="country-page-contact-us-block__employee-list-item">
                                <CountryPageEmployee {...employee} />
                            </li>
                        ))}  
                    </ul>}
                </div>
            </div>
        </div>
    )
}

CountryPageContactUsBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    addressHtml: PropTypes.string,
    phone: PropTypes.string,
    socialMediaLinks: PropTypes.arrayOf(PropTypes.exact(CountryPageSocialLink.propTypes)),
    employees: PropTypes.arrayOf(PropTypes.exact(CountryPageEmployee.propTypes)),
    lang: PropTypes.shape({
        followUsInSocialMediaHeading: PropTypes.string.isRequired 
    }).isRequired
}

export default CountryPageContactUsBlock
