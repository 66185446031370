import React, { useState, useRef, useMemo } from "react";
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import cn from 'classnames'
import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';
import TOOLTIP_POSITIONS from "./tooltip-positions";
import idHelper from 'js/id-helper'
import "./Tooltip.scss";

const Tooltip = ({ 
    children, 
    content, 
    className,
    openBtnAriaLabel,
    closeBtnAriaLabel,
    position = TOOLTIP_POSITIONS.BOTTOM
}) => {
    const [active, setActive] = useState(false);
    const tooltipRef = useRef(null);
    const uniqueTooltipId = useMemo(
        () => idHelper.generateUniqueId('tooltip'),
        []
    );

    const showTip = () => {
        setActive(true);
    };

    const hideTip = () => {
        setActive(false);
    };

    useEscape(hideTip);
    useClickOutside(tooltipRef, hideTip);

    return (
        <div
            ref={tooltipRef}
            className={cn('tooltip', className)}
        >
            <button 
                onClick={showTip} 
                className='tooltip__trigger' 
                aria-label={openBtnAriaLabel}
                aria-describedby={uniqueTooltipId}
            >
                {children}
            </button>
            {active && (
                <div 
                    id={uniqueTooltipId}
                    role="tooltip"
                    className={cn('tooltip__content', {
                        [`tooltip__content_position-${position}`]: position
                    })}
                >
                    {content}
                    <button 
                        className='tooltip__close-btn'
                        aria-label={closeBtnAriaLabel}
                        onClick={hideTip}
                    >
                        <Icon use='close' className='tooltip__close-icon' />
                    </button>
                </div>
            )}
        </div>
    );
};

Tooltip.propTypes = {
    className: PropTypes.string,
    openBtnAriaLabel: PropTypes.string,
    closeBtnAriaLabel: PropTypes.string,
    content: PropTypes.string,
    children: PropTypes.node,
    position: PropTypes.oneOf(Object.values(TOOLTIP_POSITIONS))
}

export default Tooltip