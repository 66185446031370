import React from 'react'
import PropTypes from 'prop-types'
import CountryPageLinkBlock from './CountryPageLinkBlock'
import './CountryPageLinksBlock.scss'


const CountryPageLinksBlock = ({ heading, links}) => {
    return (
        <div className="country-page-links-block">
            <div className="country-page-links-block__container">
                {heading && <h2 className="country-page-links-block__heading">{heading}</h2>}
                {links && <ul className="country-page-links-block__list">
                    {links.map((link, index) => (
                        <li className="country-page-links-block__list-item" key={index}>
                            <CountryPageLinkBlock {...link} />
                        </li>
                    ))}    
                </ul>}
            </div>
        </div>
    )
}

CountryPageLinksBlock.propTypes = {
    heading: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.exact(CountryPageLinkBlock.propTypes)).isRequired
}

export default CountryPageLinksBlock
