import React from 'react';
import { createRoot } from 'react-dom/client';

import SearchPage from 'containers/SearchPage';

document.addEventListener("DOMContentLoaded", () => {
    const body = document.body;
    const searchPageNode = document.getElementById('search-page');

    if (searchPageNode) {
        const div = document.createElement('div');
        searchPageNode.parentNode.insertBefore(div, searchPageNode);

        const root = createRoot(div);
        root.render(
            <SearchPage
                {...window.__SEARCHPAGE}
                language={body.dataset.lang}
            />
        );
    }
});
