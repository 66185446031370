import React from 'react'
import PropTypes from 'prop-types'
import PublishedDate from 'components/PublishedDate'
import Link from 'components/Link'
import RichText from 'components/RichText'
import './CountryPageLatestNewsOnMarketAccessBlock.scss'

const CountryPageLatestNewsOnMarketAccess = ({ heading, introHtml, publishedDate, publishedDateFormatted, pablishedLabel, url, headingTagLevel  }) => {
    const HeadingTag = `h${headingTagLevel}`;
    return (
        <article href={url} className="country-page-latest-news-on-market-access-block__item">
            {heading &&
                <HeadingTag className="country-page-latest-news-on-market-access-block__item-heading">
                    <Link
                        className="country-page-latest-news-on-market-access-block__item-link"
                        text={heading}
                        url={url}
                    />
                </HeadingTag>
            }
            {introHtml && <RichText className="rich-text_in-country-page-latest-news-on-market-access-block" text={introHtml} />}
            {publishedDate &&
                <PublishedDate
                    label={pablishedLabel}
                    date={publishedDate}
                    dateFormatted={publishedDateFormatted}
                    className="country-page-latest-news-on-market-access-block__published-date"
                />
            }
        </article>
    )
}

CountryPageLatestNewsOnMarketAccess.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    publishedDate: PropTypes.string,
    publishedDateFormatted: PropTypes.string,
    pablishedLabel: PropTypes.string,
    url: PropTypes.string.isRequired,
    headingTagLevel: PropTypes.oneOf([2, 3]),
}

CountryPageLatestNewsOnMarketAccess.propTypesMeta = {
    pablishedLabel: 'exclude',
    headingTagLevel: 'exclude',
}

export default CountryPageLatestNewsOnMarketAccess
