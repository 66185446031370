import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './SasHtmlInjectionBlock.scss'

export const themes = {
    default: 'default',
    lightBlue: 'light-blue',
};

const SasHtmlInjectionBlock = ({ html, theme = themes.default }) => {
    function createHtml() {
        return { __html: html };
    }

    return html ? (
        <div 
            className={cn('sas-html-injection-block', { 
                [`sas-html-injection-block_${theme}`]: theme 
            })}
        >
            <div dangerouslySetInnerHTML={createHtml()} />
        </div>
    ) : null
}

SasHtmlInjectionBlock.propTypes = {
    html: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(themes))
}

export default SasHtmlInjectionBlock
