import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import BackgroundMedia from 'components/BackgroundMedia'
import LinkWithIcon from 'components/LinkWithIcon'
import Link from 'components/LinkWithIcon'
import './CountryPageHero.scss'
import imageResizer from 'components/ResponsiveImage/ImageResizer.js'

const CountryPageHero = ({ heading, intro, image, seeAllLink }) => {
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        setImageWidth(imageResizer.calcImageWidth(Math.min(1560, window.screen.width), 100));
    });

    return (
        <div className="country-page-hero wrapper">
            {image &&
            <div className="country-page-hero__image">
                <BackgroundMedia  image={image} imageWidth={imageWidth}  />
            </div>
                
            }
            <div className="country-page-hero__text-container">
                {heading && (
                    <h1 className="country-page-hero__heading">
                            {heading}
                    </h1>
                )}
                {intro && (
                    <div className="country-page-hero__intro">
                        {intro}
                    </div>
                )}
                {seeAllLink && 
                <LinkWithIcon  
                    {...seeAllLink}
                    className="country-page-hero__see-all-link" 
                    type={LinkWithIcon.types.seeAll}
                    iconPosition={LinkWithIcon.iconPositions.left}
                />}
            </div>
        </div>
    )
}

CountryPageHero.propTypes = {
    heading: PropTypes.string,
    intro: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    seeAllLink: PropTypes.exact(Link.propTypes),
}

export default CountryPageHero
