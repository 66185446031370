import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Pager from 'components/Pager'
import Loader from 'components/Loader'
import NoResults from 'components/NoResults'
import SelectFiltersPanel from 'components/SelectFiltersPanel'
import SelectFilter from 'components/SelectFilter/SelectFilter'
import SelectFilterDateRange from 'components/SelectFilter/SelectFilterDateRange'
import ArticleListItem from './ArticleListItem'
import api from '../../js/api-helper'
import formHelper from 'js/form-helper'
import useDidUpdateEffect from '../../hooks/use-did-update-effect'
import './ArticleList.scss'

const ArticleList = ({
    loadItemsEndpoint,
    filters,
    lang
}) => {
    const [query, setQuery] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = React.useState(null)
    const [pager, setPager] = useState(null)
    const [articleListGroups, setArticleListGroups] = useState([])

    const onPageChange = page => {
        setQuery(prevQuery => ({
            ...prevQuery,
            page: page.activePageNumber
        }))
    }

    const loadArticles = () => {
        setIsLoading(true)
        setError(null)
        api.get(loadItemsEndpoint, query)
            .then(response => {
                setIsLoading(false)
                if (response.success) {
                    setArticleListGroups(response.data.articleListGroups)
                    setPager(response.data.pager)
                } else {
                    setError(response.errorMessage)
                }
            })
            .catch(() => {
                setError(lang.serverErrorText)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const queryParams = formHelper.getQueryParams()
        const initPageNumber =
            queryParams && queryParams.page ? queryParams.page : 1;

        setQuery(formHelper.fillQueryFromPageQueryString({
            page: parseInt(initPageNumber)
        }))
    }, [])

    useDidUpdateEffect(() => {
        formHelper.updatePageQueryString(query)
        loadArticles()
    }, [query]);

    const onFilterChange = (query) => setQuery(query);

    const currentPageIndex = pager ? pager.page : 1;

    return (
        <div
            className='article-list'
        >
            {
                filters && (
                    <div className='article-list__filters'>
                        <SelectFiltersPanel 
                            query={query}
                            selectFilters={[
                                filters.categories
                            ]}
                            periodFilter={filters.period}
                            expanderLabel={lang.filterLabel}
                            noResultsText={lang.noFilterResultsText}
                            onFilterChange={onFilterChange}
                        />
                    </div>
                )
            }
            {
                articleListGroups && articleListGroups.length > 0 ? (
                    articleListGroups.map((month, articleListGroupIndex) => (
                        <div key={`${currentPageIndex}-${articleListGroupIndex}`} className='article-list__month'>
                            {month.heading && <h3 className='article-list__month-heading'>{month.heading}</h3>}
                            {
                                month.articles && month.articles.length > 0 && (
                                    <div className='market-insight-reports-list__items'>
                                        {
                                            month.articles.map(article => (
                                                <ArticleListItem
                                                    key={article.id}
                                                    contactPersonLabel={lang.contactPersonLabel}
                                                    publishedLabel={lang.publishedLabel}
                                                    readMoreLabel={lang.readMoreLabel}
                                                    readLessLabel={lang.readLessLabel}
                                                    {...article} 
                                                />
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>
                    ))
                ) : null
            }
            {articleListGroups && articleListGroups.length === 0 && (
                <NoResults
                    labels={{
                        noResultsHeading: lang.noListResultsHeading,
                        noResultsText: lang.noListResultsText
                    }}
                    light
                />
            )}
            {isLoading && (
                <div className="article-list__loading">
                    <Loader />
                </div>
            )}
            {error && <div className='article-list__error'>{error}</div>}
            {pager && (
                <div className='article-list__pager'>
                    <Pager {...pager} onPageChanging={onPageChange} />
                </div>
            )}
        </div>
    )
}

ArticleList.propTypes = {
    loadItemsEndpoint: PropTypes.string.isRequired,
    filters: PropTypes.shape({
        categories: PropTypes.exact(SelectFilter.propTypes),
        period: PropTypes.exact(SelectFilterDateRange.propTypes)
    }),
    lang: PropTypes.shape({
        readMoreLabel: PropTypes.string.isRequired,
        contactPersonLabel: PropTypes.string.isRequired,
        publishedLabel: PropTypes.string.isRequired,
        noFilterResultsText: PropTypes.string.isRequired,
        readLessLabel: PropTypes.string.isRequired,
        filterLabel: PropTypes.string.isRequired,
        noListResultsHeading: PropTypes.string.isRequired,
        noListResultsText: PropTypes.string.isRequired,
        serverErrorText: PropTypes.string.isRequired,
    }).isRequired
}

export default ArticleList
