import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Pager from 'components/Pager'
import Loader from 'components/Loader'
import NoResults from 'components/NoResults'
import SelectFiltersPanel from 'components/SelectFiltersPanel'
import SelectFilter from 'components/SelectFilter/SelectFilter'
import SelectFilterDateRange from 'components/SelectFilter/SelectFilterDateRange'
import MarketInsightReportItem from './MarketInsightReportItem'
import api from '../../js/api-helper'
import formHelper from 'js/form-helper'
import useDidUpdateEffect from '../../hooks/use-did-update-effect'
import './MarketInsightReportList.scss'

const MarketInsightReportList = ({
    heading,
    loadItemsEndpoint,
    filters,
    lang
}) => {
    const [query, setQuery] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [pager, setPager] = useState(null)
    const [reportGroups, setReportGroups] = useState([])

    const onPageChange = page => {
        setQuery(prevQuery => ({
            ...prevQuery,
            page: page.activePageNumber
        }))
    }

    const loadReports = () => {
        setIsLoading(true)
        setError(null)
        api.get(loadItemsEndpoint, query)
            .then(response => {
                setIsLoading(false)
                if (response.success) {
                    setReportGroups(response.data.reportGroups)
                    setPager(response.data.pager)
                } else {
                    setError(response.errorMessage)
                }
            })
            .catch(() => {
                setError(lang.serverErrorText)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const queryParams = formHelper.getQueryParams()
        const initPageNumber =
            queryParams && queryParams.page ? queryParams.page : 1;

        setQuery(formHelper.fillQueryFromPageQueryString({
            page: parseInt(initPageNumber)
        }))
    }, [])

    useDidUpdateEffect(() => {
        formHelper.updatePageQueryString(query)
        loadReports()
    }, [query]);

    const onFilterChange = (query) => {
        if(query){
            query.page = 1;
        }
        setQuery(query);
    }

    const currentPageIndex = pager ? pager.page : 1;

    return (
        <div
            className='market-insight-report-list'
        >
            {heading && <h2 className='market-insight-report-list__heading'>{heading}</h2>}
            {
                filters && (
                    <div className='market-insight-report-list__filters'>
                        <SelectFiltersPanel
                            query={query}
                            selectFilters={[
                                filters.types,
                                filters.species,
                                filters.countries
                            ]}
                            periodFilter={filters.period}
                            expanderLabel={lang.filterLabel}
                            noResultsText={lang.noFilterResultsText}
                            onFilterChange={onFilterChange}
                        />
                    </div>
                )
            }
            {
                reportGroups && reportGroups.length > 0 ? (
                    reportGroups.map((month, reportGroupIndex) => (
                        <div key={`${currentPageIndex}-${reportGroupIndex}`} className='market-insight-report-list__month'>
                            {month.heading && <h3 className='market-insight-report-list__month-heading'>{month.heading}</h3>}
                            {
                                month.reports && month.reports.length > 0 && (
                                    <div className='market-insight-reports-list__items'>
                                        {
                                            month.reports.map(report => (
                                                <MarketInsightReportItem
                                                    key={report.id}
                                                    contactPersonLabel={lang.contactPersonLabel}
                                                    publishedLabel={lang.publishedLabel}
                                                    readMoreLabel={lang.readMoreLabel}
                                                    readLessLabel={lang.readLessLabel}
                                                    {...report} 
                                                />
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>
                    ))
                ) : null
            }
            {reportGroups && reportGroups.length === 0 && (
                <NoResults
                    labels={{
                        noResultsHeading: lang.noListResultsHeading,
                        noResultsText: lang.noListResultsText
                    }}
                    light
                />
            )}
            {isLoading && (
                <div className="market-insight-report-list__loading">
                    <Loader />
                </div>
            )}
            {error && <div className='market-insight-report-list__error'>{error}</div>}
            {pager && (
                <div className='market-insight-report-list__pager'>
                    <Pager {...pager} onPageChanging={onPageChange} />
                </div>
            )}
        </div>
    )
}

MarketInsightReportList.propTypes = {
    heading: PropTypes.string,
    loadItemsEndpoint: PropTypes.string.isRequired,
    filters: PropTypes.shape({
        types: PropTypes.exact(SelectFilter.propTypes),
        species: PropTypes.exact(SelectFilter.propTypes),
        countries: PropTypes.exact(SelectFilter.propTypes),
        period: PropTypes.exact(SelectFilterDateRange.propTypes)
    }),
    lang: PropTypes.shape({
        readMoreLabel: PropTypes.string.isRequired,
        contactPersonLabel: PropTypes.string.isRequired,
        publishedLabel: PropTypes.string.isRequired,
        noFilterResultsText: PropTypes.string.isRequired,
        readLessLabel: PropTypes.string.isRequired,
        filterLabel: PropTypes.string.isRequired,
        noListResultsHeading: PropTypes.string.isRequired,
        noListResultsText: PropTypes.string.isRequired,
        serverErrorText: PropTypes.string.isRequired,
    }).isRequired
}

export default MarketInsightReportList
