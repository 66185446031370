/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import cn from 'classnames'
import './CancelButton.scss'

const CancelButton = ({
    className,
    text,
    onClick
}) => {
    return (
        <button
            className={cn('cancel-button', className)}
            onClick={onClick}
        >
            {text && <span className='cancel-button__text'>{text}</span>}
            <Icon use="close" className="cancel-button__icon" />
        </button>
    )
}

CancelButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string
}

CancelButton.meta = 'exclude'

export default CancelButton
