import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FilterTableItem from 'components/FilterTableItem'
import FluidImage from 'components/FluidImage'

import EmployeeType from 'types/EmployeeType'

import * as css from './EmployeeListItem.scss'

const EmployeeListItem = ({
    id,
    name,
    title,
    country,
    image,
    phone,
    secondaryPhone,
    phoneFormatted,
    secondaryPhoneFormatted,
    email,
    intro,
    bulletList,
    labels,
    hide
}) => (
    <FilterTableItem
        colSpan={3}
        hide={hide}
        id={id}
        name={name}
        type="employee"
    >
        <header className={css.header}>
            <div className={css.image}>
                <FluidImage
                    className={css.profileImage}
                    size={180}
                    {...image}
                />
            </div>
            <div className={css.name}>
                <h3 className="h1">{name}</h3>
                <h4 className="lead">
                    {title}
                    <br />
                    {country}
                </h4>
            </div>
        </header>
        <section className={css.info}>
            <div className={cn(css.intro, 'wysiwyg negative')}>
                <div dangerouslySetInnerHTML={{__html: intro}} />
                {bulletList && (
                    <ul>
                        {bulletList.map(i => <li key={i}>{i}</li>)}
                    </ul>
                )}
            </div>
            <div className={cn(css.contactText, 'wysiwyg', 'negative')}>
                <h4>{labels.contactInformation}</h4>
                {phone && <a href={`tel:${phoneFormatted}`}>{phone}</a>}<br />
                {secondaryPhone && <a href={`tel:${secondaryPhoneFormatted}`}>{secondaryPhone}</a>}<br />
                {email && <a href={`mailto:${email}`}>{email}</a>}<br />
            </div>
            <div className={css.contactActions}>
                {phone && <a className="button negative" href={`tel:${phoneFormatted}`}>{labels.callMe}</a>}
                {email && <a className="button secondary negative" href={`mailto:${email}`}>{labels.sendEmail}</a>}
            </div>
        </section>
    </FilterTableItem>
)

EmployeeListItem.propTypes = {
    ...EmployeeType,
    labels: PropTypes.shape({
        callMe: PropTypes.string.isRequired,
        contactInformation: PropTypes.string.isRequired,
        sendEmail: PropTypes.string.isRequired
    }).isRequired,
    hide: PropTypes.bool.isRequired
}

export default EmployeeListItem
