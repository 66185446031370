import PropTypes from 'prop-types'
import Link from 'components/Link'
import LinkWithIcon from 'components/LinkWithIcon';

const types = LinkWithIcon.types;

const TypedLink = {};

TypedLink.propTypes = Object.assign({}, Link.propTypes, {
    type: PropTypes.oneOf(Object.values(types)),
})

TypedLink.types = types

export default TypedLink