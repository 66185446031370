import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ScrollToLink from "components/ScrollToLink"
import { MARKET_INSIGHT_STATISTICS_BLOCK_IDS } from '../../constants'
import './MarketInsightScrollToReportsBlock.scss'

const MarketInsightScrollToReportsBlock = ({ lang }) => {
    const [interactiveReportsExists, setInteractiveReportsExists] = useState(false);
    const [downloadableStatisticsExists, setDownloadableStatisticsExists] = useState(false);

    useEffect(() => {
        const interactiveReportsBlock = document.getElementById(MARKET_INSIGHT_STATISTICS_BLOCK_IDS.interactiveReports);
        const downloadableStatisticsBlock = document.getElementById(MARKET_INSIGHT_STATISTICS_BLOCK_IDS.downloadableStatistics);
        setInteractiveReportsExists(!!interactiveReportsBlock);
        setDownloadableStatisticsExists(!!downloadableStatisticsBlock);
    }, []);

    return (
        <div className='market-insight-scroll-to-reports-block'>
            {(interactiveReportsExists || downloadableStatisticsExists) && (
                <div className='market-insight-scroll-to-reports-block__panel'>
                    <span className='market-insight-scroll-to-reports-block__label'>{lang.jumpToLabel}</span>
                    <ul className='market-insight-scroll-to-reports-block__items'>
                        {interactiveReportsExists && (
                            <li className='market-insight-scroll-to-reports-block__item'>
                                <ScrollToLink
                                    text={lang.interactiveReportsLabel}
                                    targetBlockId={MARKET_INSIGHT_STATISTICS_BLOCK_IDS.interactiveReports}
                                    className='market-insight-scroll-to-reports-block__link'
                                    updateHashOnScroll={true}
                                />
                            </li>
                        )}
                        {downloadableStatisticsExists && (
                            <li className='market-insight-scroll-to-reports-block__item'>
                                <ScrollToLink
                                    text={lang.downloadableStatisticsLabel}
                                    targetBlockId={MARKET_INSIGHT_STATISTICS_BLOCK_IDS.downloadableStatistics}
                                    className='market-insight-scroll-to-reports-block__link'
                                    updateHashOnScroll={true}
                                />
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}

MarketInsightScrollToReportsBlock.propTypes = {
    lang: PropTypes.shape({
        jumpToLabel: PropTypes.string.isRequired,
        interactiveReportsLabel: PropTypes.string.isRequired,  
        downloadableStatisticsLabel: PropTypes.string.isRequired,  
    }).isRequired, 
};

export default MarketInsightScrollToReportsBlock;
