import isRunningOnClient from '@creuna/utils/is-running-on-client';

function matchMediaQuery(size) {
  return function (mobileFirst = 'true') {
    return isRunningOnClient
      ? window.matchMedia(`(${mobileFirst ? 'min-width' : 'max-width'}: ${size}px)`).matches
      : null;
  };
}

const breakpoints = {
  s: 0,
  m: 960,
  l: 1024,
  xl: 1320
};

export default {
  s: matchMediaQuery(breakpoints.s),
  m: matchMediaQuery(breakpoints.m),
  l: matchMediaQuery(breakpoints.l),
  xl: matchMediaQuery(breakpoints.xl),
};