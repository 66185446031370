import React from 'react'
import PropTypes from 'prop-types'

import FilterList from 'hoc/FilterList'
import FilterTable from 'components/FilterTable'
import MarketplaceRequest from 'components/MarketplaceRequest'
import Species from 'components/Species'

import MarketplaceRequestType from 'types/MarketplaceRequestType'

import * as css from './Marketplace.scss'

class Marketplace extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(MarketplaceRequestType)).isRequired,
        labels: PropTypes.shape({
            date: PropTypes.string.isRequired,
            company: PropTypes.string.isRequired,
            species: PropTypes.string.isRequired,
            region: PropTypes.string.isRequired,
            resultsOnFormatLabel: PropTypes.string,
            resultsCountFormatLabel: PropTypes.string
        }).isRequired,
        listHeading: PropTypes.string,
        shown: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        onToggle: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired,
        searchValue: PropTypes.string
    }

    static defaultProps = {
        labels: {
            resultsCountFormatLabel: "{0} treff",
            resultsOnFormatLabel: "på {0}"
        }
    }

    render() {
        const { items, labels, shown, searchValue, listHeading, totalItems } = this.props

        return (
            <FilterTable
                classNames={{
                    row: css.row
                }}
                component={MarketplaceRequest}
                headers={[
                    labels.date,
                    labels.company,
                    labels.species,
                    labels.region,
                ]}
                items={items}
                labels={labels}
                name={item => item.company}
                onToggle={this.props.onToggle}
                rows={[
                    item => item.date,
                    item => item.company,
                    item => <Species species={item.species} />,
                    item => item.regions && item.regions.map(s => s.name).join(', '),
                ]}
                shown={shown}
                type="request"
                searchValue={searchValue}
                caption={listHeading}
                totalItems={totalItems}
            />
        )
    }
}

export default FilterList(Marketplace)
