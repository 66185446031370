import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select';

import Filter from 'components/Filter'
import ReactSelectWrapper from 'components/ReactSelectWrapper'

import FilterType from 'types/FilterType'
import idHelper from 'js/id-helper'

function onChangeHandler(value) {
    this.onChange(this.type, value)
}

function getSelected(options, selected){   
    let newValue;

    if (typeof selected === 'string') {
        let stringToArr = selected.split(",");
        newValue = options.filter( i => stringToArr.includes(i.value));
    }else{
        newValue = selected
    }
    
    return newValue
} 

const FilterCategory = ({ type, labels, categories, types, onChange, value }) => {
    const [id] = useState(idHelper.generateUniqueId('filter-category'));
    const labelId = `${id}-label`;
    let options = [];

    if(categories || types){
        options = categories ? categories.map(c => ({ value: c.id.toString(), label: c.name })) : types && types.map(t => ({ value: t, label: t }));
    }

    return (
    <Filter title={labels.title} id={labelId}>
        {(categories || types) && (
            <ReactSelectWrapper>
                <ReactSelect
                    backspaceToRemoveMessage=""
                    closeOnSelect={false}
                    joinValues={true}
                    isMulti
                    name={`filter_${type}`}
                    onChange={onChangeHandler.bind({ type, onChange })}
                    options={options}
                    placeholder={labels.addFilter}
                    simpleValue={true}
                    value={getSelected(options, value)}
                    classNamePrefix="Select"
                    instanceId={`FilterCategory_select-${id}`}
                    aria-labelledby={labelId}
                    noOptionsMessage={() => labels.noOptionsMessage}
                />
            </ReactSelectWrapper>
        )}
    </Filter>
)}

FilterCategory.defaultProps = {
    value: []
}

FilterCategory.propTypes = {
    ...FilterType,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
        PropTypes.oneOf([null, undefined])
    ])
}

export default FilterCategory
