import React from 'react'
import PropTypes from 'prop-types'
import Image from 'components/ResponsiveImage'
import isRunningOnClient from '@creuna/utils/is-running-on-client'

const SharePanelLink = ({ icon, url, label }) => {
    const printPage = () => {
        isRunningOnClient && window.print()
    }

    var image = (
        <img
            src={icon.src}
            alt={icon.alt}
            className="share-panel__item-image"
        />
    )

    return (
        <React.Fragment>
            {url === '#print' ? (
                <a
                    className="share-panel__item"
                    aria-label={label}
                    onClick={printPage}
                    tabIndex="1"
                >
                    {image}
                </a>
            ) : (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-panel__item"
                    aria-label={label}
                >
                    {image}
                </a>
            )}
        </React.Fragment>
    )
}

SharePanelLink.propTypes = {
    icon: PropTypes.exact(Image.propTypes),
    url: PropTypes.string.isRequired,
    label: PropTypes.string
}

export default SharePanelLink
