import React from 'react';
import PropTypes from 'prop-types';
import NavigationArea from 'components/NavigationArea';

import * as css from './NavigationAreas.scss';

const NavigationAreas = ({
    areas
}) => {
    return (
        <div className={css.navigationAreas}>
            <div className={css.navigationAreasWrapper}>
                {
                    areas.length > 0 && areas.map((item, index) => (
                        <div className={css.navigationAreasColumn} key={index}>
                            <NavigationArea {...item} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

NavigationAreas.propTypes = {
    areas: PropTypes.arrayOf(PropTypes.exact(NavigationArea.propTypes)).isRequired,
};

export default NavigationAreas;
