import React from 'react'
import PropTypes from 'prop-types'

class AddToCalendar extends React.Component {
    static propTypes = {
        addToCalendarLabel: PropTypes.string.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        timeZone: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        location: PropTypes.string.isRequired,
        organizer: PropTypes.shape({
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        })
    }

    componentDidMount() {
        if(window.addtocalendar && window.addtocalendar.load) window.addtocalendar.load()
    }

    render() {
        const {
            addToCalendarLabel,
            start,
            end,
            timeZone,
            title,
            description,
            location,
            organizer
        } = this.props

        return (
            <span className="addtocalendar">
                <a className="atcb-link">{addToCalendarLabel}</a>
                <var className="atc_event">
                    <var className="atc_date_start">{start}</var>
                    <var className="atc_date_end">{end}</var>
                    <var className="atc_timezone">{timeZone}</var>
                    <var className="atc_title">{title}</var>
                    <var className="atc_description">{description}</var>
                    <var className="atc_location">{location}</var>
                    {organizer && organizer.name && <var className="atc_organizer">{organizer.name}</var>}
                    {organizer && organizer.email && <var className="atc_organizer_email">{organizer.email}</var>}
                </var>
            </span>
        )
    }
}

export default AddToCalendar
