import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import jquery from 'jquery'

import * as css from './Breadcrumbs.scss'

class Breadcrumbs extends React.Component {
    static propTypes = {
        breadcrumbs: PropTypes.array.isRequired,
        breadcrumbsLabel: PropTypes.string.isRequired,
        isPositive: PropTypes.bool.isRequired,
    }

    static defaultProps = {
        isPositive: true
    }

    _showCrumbs = () => {
        jquery(this.toggler).fadeOut(200, () => {
            jquery('li:nth-child(n+3):not(:last-child)', this.list).fadeIn(200)
            window.addEventListener('scroll', this._hideCrumbs)
            window.addEventListener('resize', this._hideCrumbs)
        })
    }

    _hideCrumbs = () => {
        window.removeEventListener('scroll', this._hideCrumbs)
        window.removeEventListener('resize', this._hideCrumbs)

        const items = jquery('li:nth-child(n+3):not(:last-child)', this.list)
        items.fadeOut(200, () => {
            jquery(this.toggler).fadeIn(200, () => {
                jquery('li', this.list).removeAttr('style')
            })
        })
    }

    render() {
        const {
            breadcrumbs,
            breadcrumbsLabel,
            isPositive
        } = this.props

        return (
            <nav
                className={cn(css.breadcrumbs, {
                    [css.positive]: isPositive
                })}
                itemProp="breadcrumb"
                itemScope itemType="http://schema.org/BreadcrumbList"
                aria-label={breadcrumbsLabel}
            >
                <ol className={cn({[css.many]: breadcrumbs && breadcrumbs.length >= 3})} ref={node => this.list = node}>
                    {breadcrumbs && breadcrumbs.length >= 3 && (
                        <li className={css.toggler} ref={node => this.toggler = node}>
                            <a onClick={this._showCrumbs} role="button">...</a>
                        </li>
                    )}
                    {breadcrumbs.map((b,i) => (
                        <li
                            itemProp="itemListElement"
                            itemScope
                            itemType="http://schema.org/ListItem"
                            key={i}
                        >
                            {i !== 0 && <span aria-hidden="true" className={css['breadcrumbs__separator']}>&#47;</span>}
                            <a href={b.url} itemProp="item" className={css['breadcrumbs__link']}>{b.title}</a>
                            <meta itemProp="position" content={i} />
                            <meta itemProp="name" content={b.title} />
                        </li>
                    ))}
                </ol>
            </nav>
        )
    }
}

export default Breadcrumbs
