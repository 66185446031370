// extracted by mini-css-extract-plugin
var _1 = "oN0XFe9vG8s1LgiUrUD7";
var _2 = "hKQeDULP0vAeb_a2gPXD";
var _3 = "kUSfAMkQkKyEc4PVNirK";
var _4 = "epFqRSApyal5r41RML47";
var _5 = "YDE4JOlQcD83NGBw87jx";
var _6 = "o6FhpdFo1pjpI8vyKrtn";
var _7 = "liU_lkDgmHaB1P66NGy0";
var _8 = "Upol3tSyhcpYZZLOprdg";
export { _1 as "socialMediaLink", _2 as "socialMediaLinkIcon", _3 as "socialMediaLinkText", _4 as "socialMediaLinksBlock", _5 as "socialMediaLinksWrapper", _6 as "socialMediaList", _7 as "socialMediaListItem", _8 as "socialMediaListTitle" }
