import React from 'react'
import ClientOnly from 'components/ClientOnly';
import PropTypes from 'prop-types'

// Wrapper component for ReactSelect (https://www.npmjs.com/package/react-select) that conditionally renders it only on the client-side.
// This is specifically designed to address server-side rendering (SSR) issue https://github.com/JedWatson/react-select/issues/5555,
// ensuring proper functionality and performance.

const ReactSelectWrapper = ({ children }) => (
    <ClientOnly>
        {children}
    </ClientOnly>
)

ReactSelectWrapper.propTypes = {
    children: PropTypes.node,
}

export default ReactSelectWrapper;