import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import LinkTab from './LinkTab'
import cn from 'classnames'

import './LinkTabs.scss'

const LinkTabs = ({
    tabs
}) => {
    return tabs && tabs.length > 0 ? <div className='link-tabs'>
        <nav className='link-tabs__container'>
            <ul className='link-tabs__list'>
                {tabs.map((tab, index) => {
                    return (
                        <li className='link-tabs__item' key={index}>
                            <Link {...tab} className={cn('link-tabs__link', 
                                { 'link-tabs__link_active': tab.isActive }
                            )} />
                        </li>
                    )
                })}
            </ul>
        </nav>   
    </div>: null
}

LinkTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.exact(LinkTab.propTypes)).isRequired,
}

export default LinkTabs

