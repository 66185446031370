
import React from 'react';
import PropTypes from 'prop-types';
import './SimpleTag.scss'

const SimpleTag = ({ text }) => {
    return (
        <div className='simple-tag'>
            {text}
        </div>
    )
}

SimpleTag.propTypes = {
    text: PropTypes.string
};

SimpleTag.propTypesMeta = 'exclude';

export default SimpleTag;