import React from 'react';
import { createRoot } from 'react-dom/client';

import Video from 'containers/Video';

document.addEventListener('DOMContentLoaded', () => {
    const buttons = Array.from(document.querySelectorAll('.video-button'));

    if (buttons.length) {
        const body = document.body;
        const div = document.createElement('div');
        body.appendChild(div);

        const root = createRoot(div);
        root.render(<Video buttons={buttons} />);
    }
});
