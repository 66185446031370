import React from 'react'
import PropTypes from 'prop-types'
import LinkWithIcon from 'components/LinkWithIcon'
import TypedLink from 'components/Models/TypedLink/TypedLink'
import './RichTextLinksBlock.scss'

const RichTextLinksBlock = ({ links }) => {
    return links ? (
        <ul className="rich-text-links-block">
            {links.map((link, index) => (
                <li key={index} className="rich-text-links-block__item">
                    <LinkWithIcon {...link}/>
                </li>
            ))}
        </ul>    
    ) : null
}

RichTextLinksBlock.propTypes = {
    links: PropTypes.arrayOf(TypedLink.propTypes),
}

export default RichTextLinksBlock
