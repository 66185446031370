import React from 'react'
import PropTypes from 'prop-types'
import jQuery from 'jquery'

import FilterList from 'hoc/FilterList'
import FilterTable from 'components/FilterTable'
import Material from 'components/Material'
import Species from 'components/Species'
import OrderListModal from 'components/OrderListModal'

import MaterialType from 'types/MaterialType'

class MaterialList extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(MaterialType)).isRequired,
        labels: PropTypes.shape({
            type: PropTypes.string.isRequired,
            species: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            contacts: PropTypes.string.isRequired,
            downloadMaterial: PropTypes.string.isRequired,
            orderMaterial: PropTypes.string.isRequired,
            resultsOnFormatLabel: PropTypes.string,
            resultsCountFormatLabel: PropTypes.string
        }).isRequired,
        user: PropTypes.object,
        enriched: PropTypes.bool.isRequired,
        listHeading: PropTypes.string,
        shown: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onToggle: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired,
        orderSubmitUrl: PropTypes.string,
        orderErrorMessage: PropTypes.string,
        coFinancing: PropTypes.bool,
        searchValue: PropTypes.string
    }

    static defaultProps = {
        enriched: false,
        labels: {
            resultsCountFormatLabel: "{0} treff",
            resultsOnFormatLabel: "på {0}"
        }
    }

    state = {
        user: this.props.user || {},
        isOrdering: false,
        orders: {},
        orderComplete: false,
        orderError: false,
        orderList: false
    }

    componentDidMount() {
        try {
            const orders = window.localStorage.getItem('orders')
            if (orders) {
                this.setState(state => ({
                    ...state,
                    orders: JSON.parse(orders)
                }))
            }
        } catch (err) {
            console.error(err)
        }
    }

    _saveOrder = () => {
        try {
            window.localStorage.setItem(
                'orders',
                JSON.stringify(this.state.orders)
            )
        } catch (err) {
            console.error(err)
        }
    }

    _removeOrder = () => {
        try {
            window.localStorage.removeItem('orders')
        } catch (err) {
            console.error(err)
        }
    }

    _handleOrderClick = id => {
        const material = this.props.items.find(item => item.id === id)

        if (!material) {
            return console.error(`Could not find material with id: ${id}`)
        }

        if (this.state.orders[id]) {
            // Just open modal
            return this.setState({ orderList: true })
        }

        this.setState(
            state => ({
                orders: {
                    ...state.orders,
                    [id]: {
                        name: material.name,
                        image: material.image && material.image.url,
                        tags: [material.type].concat(
                            material.languages.map(l => l.name)
                        ),
                        amount: 1
                    }
                },
                orderList: true
            }),
            this._saveOrder
        )
    }

    _handleOrderAmountChange = (id, amount) =>
        this.setState(
            state => ({
                orders: {
                    ...state.orders,
                    [id]: {
                        ...state.orders[id],
                        amount
                    }
                }
            }),
            this._saveOrder
        )

    _handleOrderRemove = id =>
        this.setState(state => {
            const newState = { ...state }
            delete newState.orders[id]
            return newState
        }, this._saveOrder)

    _handleOrderCancel = () => {
        this.setState(
            {
                orders: {},
                orderList: false,
                orderError: false
            },
            this._removeOrder
        )
    }

    _handleOrderDone = () =>
        this.setState(
            {
                orderList: false,
                orderComplete: false,
                orderError: false
            },
            this._removeOrder
        )

    _handleOrderComplete = () => {
        const { user, orders } = this.state

        if (!this.props.orderSubmitUrl)
            return console.error(
                'MaterialListPage :: Missing orderSubmitUrl property'
            )

        this.setState({ isOrdering: true }, () => {
            jQuery
                .ajax({
                    url: this.props.orderSubmitUrl,
                    method: 'POST',
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                    data: JSON.stringify({
                        user: user,
                        orders: Object.keys(orders).map(id => ({
                            amount: orders[id].amount,
                            name: `${orders[id].name} | ${orders[id].tags.join(
                                ', '
                            )}`
                        }))
                    })
                })
                .always(res => {
                    if (res.status === 200) {
                        this.setState(
                            {
                                orders: {},
                                orderComplete: true,
                                orderError: false,
                                isOrdering: false
                            },
                            this._removeOrder
                        )
                    } else {
                        this.setState({
                            orderError: true,
                            isOrdering: false
                        })
                    }
                })
        })
    }

    _handleOrderListClick = () => this.setState({ orderList: true })
    _handleOrderListModalClose = () =>
        this.setState({ orderList: false, orderError: false })

    _handleUserChange = e => {
        e.persist()

        this.setState({
            user: {
                ...this.state.user,
                [e.currentTarget.name]: e.currentTarget.value
            }
        })
    }

    render() {
        const {
            items,
            labels,
            shown,
            enriched,
            orderErrorMessage,
            coFinancing,
            searchValue,
            listHeading,
            totalItems,
        } = this.props

        const {
            orders,
            orderList,
            user,
            orderComplete,
            isOrdering,
            orderError
        } = this.state

        const headers = enriched
            ? [labels.name, labels.type, labels.language]
            : [labels.type, labels.species]

        const rows = enriched
            ? [
                  item => item.name,
                  item => item.type,
                  item =>
                      item.languages &&
                      item.languages.map(c => c.name).join(', ')
              ]
            : [item => item.type, item => <Species species={item.species} />]

        if (coFinancing) {
            headers.push(labels.coFinancingPercentage)
            rows.push(
                item =>
                    item.coFinancingPercentage !== '0'
                        ? item.coFinancingPercentage
                        : ''
            )
        }
        if (!coFinancing && !enriched) {
            headers.push(labels.countries)
            rows.push(
                item =>
                    item.countries && item.countries.map(c => c.name).join(', ')
            )
        }

        return (
            <div>
                {enriched &&
                    orderList && (
                        <OrderListModal
                            labels={labels}
                            orders={orders}
                            isOrdering={isOrdering}
                            orderComplete={orderComplete}
                            onAmountChange={this._handleOrderAmountChange}
                            onCancel={this._handleOrderCancel}
                            onClose={this._handleOrderListModalClose}
                            onDone={this._handleOrderDone}
                            onComplete={this._handleOrderComplete}
                            onRemove={this._handleOrderRemove}
                            onUserChange={this._handleUserChange}
                            user={user}
                            orderError={orderError}
                            orderErrorMessage={orderErrorMessage}
                        />
                    )}
                <FilterTable
                    component={{
                        component: Material,
                        props: enriched
                            ? {
                                  enriched: true,
                                  onOrderClick: this._handleOrderClick,
                                  onOrderListClick: this._handleOrderListClick,
                                  coFinancing
                              }
                            : {
                                  coFinancing
                              }
                    }}
                    headers={headers}
                    items={items}
                    labels={labels}
                    onToggle={this.props.onToggle}
                    rows={rows}
                    shown={shown}
                    type="material"
                    searchValue={searchValue}
                    caption={listHeading}
                    totalItems={totalItems}
                />
            </div>
        )
    }
}

export default FilterList(MaterialList)
