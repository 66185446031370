import React from 'react'
import PropTypes from 'prop-types'
import CountryPageLatestReportsAndPublicationsBlockItem from './CountryPageLatestReportsAndPublicationsBlockItem'
import LinkWithIcon from 'components/LinkWithIcon'
import Link from 'components/Link'
import './CountryPageLatestReportsAndPublicationsBlock.scss'

const CountryPageLatestReportsAndPublicationsBlock = ({ heading, lang, items, previousPublicationsLink }) => 
    <div className="country-page-latest-reports-and-publications-block">
        {heading && <h2 className="country-page-latest-reports-and-publications-block__heading">{heading}</h2>}

        {items && 
            <ul className="country-page-latest-reports-and-publications-block__items">
                {items.map((item, index) => (
                    <li className="country-page-latest-reports-and-publications-block__item" key={index}>
                        <CountryPageLatestReportsAndPublicationsBlockItem {...item} publishedLabel={lang.publishedLabel} />
                    </li>
                ))}
            </ul>
        }  
        {previousPublicationsLink && <LinkWithIcon
            className="country-page-latest-reports-and-publications-block__previous-publications-link"
            {...previousPublicationsLink}
        />}
    </div>

CountryPageLatestReportsAndPublicationsBlock.propTypes = {
    heading: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact(CountryPageLatestReportsAndPublicationsBlockItem.propTypes)),
    lang: PropTypes.shape({
        publishedLabel: PropTypes.string.isRequired,
    }).isRequired,
    previousPublicationsLink: PropTypes.exact(Link.propTypes)

}

export default CountryPageLatestReportsAndPublicationsBlock
