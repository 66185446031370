/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import cn from 'classnames'
import './LinkWithIcon.scss'
import Icon from 'components/Icon'

const iconPositions = {
    right: 'right',
    left: 'left',
}

const types = {
    regular: 'regular',
    external: 'external',
    restricted: 'restricted',
    download: 'download',
    seeAll: 'see-all'
}

const sizes = {
    small: 'small',
    medium: 'medium',
    large: 'large'
}

const iconName = type => {
    switch (type) {
        case types.external:
            return 'external'
        case types.restricted:
            return 'lock'
        case types.download:
            return 'download-outline'
        case types.regular:
            return 'arrow-right'
        case types.seeAll:
            return 'arrow-left' 
    }
}

const LinkWithIcon = ({
    className,
    openInNewTab = false,
    url,
    text,
    hideIcon = false,
    type = types.regular,
    size = sizes.medium,
    darkMode = false,
    iconPosition = iconPositions.right
}) => {

    const RootComponent = url ? 'a' : 'span'

    const props = {
        ...(url && {
            href: url,
            target: openInNewTab ? '_blank' : null,
            rel: openInNewTab ? 'noopener noreferrer' : null
        })
    }

    return (
        <RootComponent
            className={cn('link-with-icon', className, {
                [`link-with-icon_${type}`]: type,
                [`link-with-icon_size-${size}`]: size,
                ['link-with-icon_dark-mode']: darkMode,
                [`link-with-icon_icon-position-${iconPosition}`]: iconPosition
            })}
            {...props}
        >
            {text && <span className={cn('link-with-icon__text')}>{text}</span>}
            {!hideIcon && <Icon use={iconName(type)} className="link-with-icon__icon" />}
        </RootComponent>
    )
}

LinkWithIcon.propTypes = Object.assign({}, Link.propTypes, {
    hideIcon: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(sizes)),
    darkMode: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(types)),
    iconPosition: PropTypes.oneOf(Object.values(iconPositions)),
})

LinkWithIcon.meta = 'exclude'
LinkWithIcon.types = types
LinkWithIcon.sizes = sizes
LinkWithIcon.iconPositions = iconPositions

export default LinkWithIcon
