import React, { useState } from 'react'
import ResponsiveImage from 'components/ResponsiveImage'
import EmbeddedVideo from 'components/EmbeddedVideo'
import Popup from 'components/Popup'
import Icon from 'components/Icon'
import MediaCaption from 'components/MediaCaption'
import ZoomButton from 'components/ZoomButton'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './VideoBlock.scss'

const VideoBlock = ({
    videoUrl,
    className,
    previewImage,
	playButtonLabel,
	captionHtml,
	inRichText = false,
	videoDescription,
	lang = {
    closeModalButtonLabel:'Lukk',
    zoomAriaLabel: 'Øke'
}
}) => {
    const [isOpened, setIsOpened] = useState(false)

    const playVideo = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpened(true)
    }

    return (
        <div
            className={cn("video-block", {
                [className]: className,
                "video-block_in-rich-text": inRichText
            })}
        >
			<div className="video-block__container">
				{previewImage && (
					<ResponsiveImage
						className="responsive-image_size_video-block"
						{...previewImage}
						fluid={true}
						transform="fill"
					/>
				)}
				<button
					className="video-block__play-btn"
					onClick={playVideo}
				>
					<Icon className="video-block__play-btn-icon" use="chevron-right" size={14} />
					{playButtonLabel && <span className="video-block__play-btn-label">{playButtonLabel}</span>}
				</button>
				<ZoomButton className="video-block__zoom-btn" onClick={playVideo} ariaLabel={lang.zoomAriaLabel} />
			</div>
			{captionHtml && (
                <MediaCaption textHtml={captionHtml} className="video-block__caption" />
            )}
			<Popup 
                isOpened={isOpened} 
                contentClassName="video-block__popup-content-container"
                onClose={() => setIsOpened(false)}
				closeModalButtonLabel={lang.closeModalButtonLabel}
				modalAriaLabel={videoDescription}
            >
				<div className="video-block__frame-container">
					<EmbeddedVideo
						videoUrl={videoUrl}
					/>
				</div>     
            </Popup>
        </div>
    )
}

VideoBlock.propTypes = {
    previewImage: PropTypes.exact(ResponsiveImage.propTypes),
    videoUrl: PropTypes.string,
    className: PropTypes.string,
    captionHtml: PropTypes.string,
    playButtonLabel: PropTypes.string,
    inRichText: PropTypes.bool,
    videoDescription: PropTypes.string,
    lang: PropTypes.shape({
        closeModalButtonLabel: PropTypes.string.isRequired,
        zoomAriaLabel: PropTypes.string.isRequired,
    }).isRequired
}

VideoBlock.propTypesMeta = {
    className: 'exclude',
    imageSettings: 'exclude',
    fluid: 'exclude',
}

export default VideoBlock
