import React from "react";
import PropTypes from "prop-types";
import "./JumpTo.scss";

const JumpTo = ({ heading }) => {
    const [links, setLinks] = React.useState([]);

    const scrollToView = (target) => {
        let anchor = document.getElementById(target);
        history.replaceState(null, null, `#${target}`);
        anchor.scrollIntoView({ behavior: "smooth" });
    };

    const findAnchors = (query) => {
        query = query || {};
        var anchors = document.querySelectorAll("a");
        if (query.idFilter) {
            return Array.prototype.filter.call(anchors, (anchor) =>
                RegExp(query.idFilter).test(anchor.id)
            );
        }
        return Array.from(anchors);
    };

    const fetchAnchorLinks = () => {
        var anchors = findAnchors({ idFilter: /^./ /* non empty*/ });
        var links = anchors.map((anchor) => {
            var header = anchor.closest("h1, h2, h3, h4, h5, h6");
            var anchorText = anchor.getAttribute('data-text');
            var anchorLinkText;

            if (!anchorText && header) {
                anchorLinkText = header.textContent.trim();
            } else {
                anchorLinkText = anchorText;
            }

            if(anchorLinkText) {
                return {
                    id: anchor.id,
                    text: anchorLinkText,
                };
            } else {
                return null;
            }
        });

        return links.filter((anchor) => anchor != null);
    };
    const goToAnchorInUrl = () => {
        if (window.location.hash) {
            let anchorId = window.location.hash.substring(1);
            let anchor = document.getElementById(anchorId);
           
            if(anchor){
                scrollToView(anchorId);
            }
        }
    };

    React.useEffect(() => {
        setTimeout(() => {
            goToAnchorInUrl();
        }, 1500);

        let fetchLinksRunCount = 0;

        const fetchLinksRun = () => {
            fetchLinksRunCount++;
            setLinks(fetchAnchorLinks());
            if (fetchLinksRunCount < 5) {
                setTimeout(fetchLinksRun, 1000);
            }
        };

        setTimeout(fetchLinksRun, 100);
    }, []);

    return links ? (
        <ul className="jump-to">
            {heading && (
                <li className="jump-to__item">
                    <h2 className="jump-to__heading">{heading}</h2>
                </li>
            )}
            {links.map((link, index) => (
                <li key={index}  className="jump-to__item">
                    <a
                        className="jump-to__link"
                        href={link.href}
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToView(link.id);
                        }}
                    >
                        {link.text}
                    </a>
                </li>
            ))}
        </ul>
    ) : null;
};

JumpTo.propTypes = {
    heading: PropTypes.string,
};

export default JumpTo;
