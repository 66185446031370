import React from 'react'
import PropTypes from 'prop-types'

import FilterList from 'hoc/FilterList'
import FilterTable from 'components/FilterTable'
import Office from 'components/Office'

class Offices extends React.Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        labels: PropTypes.shape({
            officesSingularFormat: PropTypes.string.isRequired,
            officesPluralFormat: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            resultsOnFormatLabel: PropTypes.string,
            resultsCountFormatLabel: PropTypes.string
        }).isRequired,
        listHeading: PropTypes.string,
        shown: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        onToggle: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired,
        searchValue: PropTypes.string
    }

    static defaultProps = {
        labels: {
            resultsCountFormatLabel: "{0} treff",
            resultsOnFormatLabel: "på {0}"
        }
    }

    render() {
        const { items, labels, shown, searchValue, listHeading, totalItems } = this.props

        return (
            <FilterTable
                component={Office}
                headers={[
                    labels.country,
                    labels.city,
                ]}
                items={items}
                labels={labels}
                onToggle={this.props.onToggle}
                rows={[
                    item => item.country,
                    item => item.city,
                ]}
                shown={shown}
                type="office"
                searchValue={searchValue}
                caption={listHeading}
                totalItems={totalItems}
            />
        )
    }
}

export default FilterList(Offices)
