import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import MediaCaption from 'components/MediaCaption'
import Photographer from 'components/Photographer'
import './ImageBlock.scss'
import cn from 'classnames'

import ImageWithPopup from 'components/ImageWithPopup'

const ImageBlock = ({ caption, image, photographer, className, inRichText = false }) => {
    return (
        <div className={cn("image-block", className, {
            'image-block_in-rich-text': inRichText
        })}>
            <ImageWithPopup image={image} photographer={photographer} />
            {caption && (
                <MediaCaption textHtml={caption} className="image-block__caption" />
            )}
        </div>
    )
}

ImageBlock.propTypes = {
    inRichText: PropTypes.bool,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    caption: PropTypes.string,
    photographer: PropTypes.exact(Photographer.propTypes),
    className: PropTypes.string
}

export default ImageBlock
