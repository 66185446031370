import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import EmployeeCard from 'components/EmployeeCard'
import FilterTableItem from 'components/FilterTableItem'

import OfficeType from 'types/OfficeType'

import * as css from './Office.scss'

const Office = ({
    hide,
    address,
    phoneNumber,
    formattedPhoneNumber,
    country,
    countryReport,
    employees,
    intro,
    mapLink,
    labels,
    id,
}) => (
    <FilterTableItem
        colSpan={3}
        hide={hide}
        id={id}
        name={country}
        type="office"
    >
        <h2 className={cn(css.country, 'h1')}>{country}</h2>
        <div className={css.info}>
            <div className={css.intro}>
                <div
                    className="wysiwyg negative"
                    dangerouslySetInnerHTML={{ __html: intro }}
                />
                {countryReport && countryReport.url && (
                    <div className={css.countryReport}>
                        <a
                            className="button small negative"
                            href={countryReport.url}
                            target={countryReport.openLinkInNewWindow ? "_blank" : "_self"}
                        >{countryReport.name}</a>
                    </div>
                )}
            </div>
            <div className={css.details}>
                {employees && employees.length > 0 && (
                    <div className={css.employees}>
                        <h3 className="h4">{labels.employees}</h3>
                        {employees.map(e => (
                            <EmployeeCard
                                key={e.id}
                                {...e}
                                negative={true}
                            />
                        ))}
                    </div>
                )}
                <div>
                    <h3 className="h4">{labels.address}</h3>
                    <div
                        className="typo-small"
                        dangerouslySetInnerHTML={{__html: address}}
                    />
                </div>
                <div className={cn('wysiwyg', 'negative', 'typo-small')}>
                    {phoneNumber && <a href={`tel:${formattedPhoneNumber}`}>{phoneNumber}</a>}
                </div>
                {mapLink && (
                    <div className={css.map}>
                        <a
                            className="shortcut negative typo-small"
                            href={mapLink}
                            rel="noopener noreferrer"
                            target="_blank"
                        >{labels.mapLink}</a>
                    </div>
                )}
            </div>
        </div>
    </FilterTableItem>
)

Office.propTypes = {
    ...OfficeType,
    hide: PropTypes.bool.isRequired,
    labels: PropTypes.shape({
        readMoreAboutOfficeFormat: PropTypes.string.isRequired
    }).isRequired
}

export default Office
