import 'airbnb-js-shims'

import './style/global.scss'
import './style/app.scss'

import './utils'
import 'modules/footer'
import 'modules/icons'
import 'modules/menu'
import 'modules/my-page'
import 'modules/search'
import 'modules/video'
import 'modules/page-scroll'

import React from 'react'
import ReactDOM from 'react-dom/client'

import Marketplace from 'containers/Marketplace'
import Offices from 'containers/Offices'
import EventList from 'containers/EventList'
import MaterialList from 'containers/MaterialList'
import EmployeeList from 'containers/EmployeeList'
import SearchBlock from 'containers/SearchBlock'
import SearchPage from 'containers/SearchPage'
import NewsFolder from 'containers/NewsFolder'
import ExporterRegistry from 'containers/ExporterRegistry'
import MarketGroup from 'containers/MarketGroup'
import EventListBlock from 'containers/EventListBlock'
import ReportList from 'containers/ReportList'
import PageSubscription from 'containers/PageSubscription'
import Newsletter from 'containers/Newsletter'
import ImageInput from 'containers/ImageInput'
import WeekMonthStatistics from 'containers/WeekMonthStatistics'
import NavigationAreas from 'containers/NavigationAreas'

import ArticleCard from 'components/ArticleCard'
import EmployeeCard from 'components/EmployeeCard'
import EmployeesBlock from 'components/EmployeesBlock'
import Breadcrumbs from 'components/Breadcrumbs'
import StepBlock from 'components/StepBlock'
import SharePanel from 'components/SharePanel'
import Pagination from 'components/Pagination'
import AddToCalendar from 'components/AddToCalendar'
import SearchOverlay from 'components/SearchOverlay'
import Select from 'components/Select'
import ScrollHint from 'components/ScrollHint'
import StatisticsBlock from 'components/StatisticsBlock'
import Banner from 'components/Banner'

import LatestArticlesBlock from 'components/LatestArticlesBlock'
import LatestChroniclesBlock from 'components/LatestChroniclesBlock'
import PrioritizedMarketsBlock from 'components/PrioritizedMarketsBlock'
import PressContactsBlock from 'components/PressContactsBlock'
import NewsPageHeader from 'components/NewsPageHeader'
import JumpTo from 'components/JumpTo'
import ShortcutsBlock from 'components/ShortcutsBlock'
import SocialMediaLinksBlock from 'components/SocialMediaLinksBlock'
import TopMediaBlock from 'components/TopMediaBlock'
import CtaBlock from 'components/CtaBlock'
import EmbeddedFrame from 'components/EmbeddedFrame'
import ImageBlock from 'components/ImageBlock'
import HeroBlock from 'components/HeroBlock'
import ParallaxEffectBlock from 'components/ParallaxEffectBlock'
import RichTextBlock from 'components/RichTextBlock'
import InfoBlock from 'components/InfoBlock'
import FullPageQuotationBlock from 'components/FullPageQuotationBlock'
import ImageTextBlock from 'components/ImageTextBlock'
import FullPageImageBlock from 'components/FullPageImageBlock'
import VideoBlock from 'components/VideoBlock'
import FeatureArticleAuthor from 'components/FeatureArticleAuthor'
import FeatureArticleSources from 'components/FeatureArticleSources'
import MoreFeatureArticles from 'components/MoreFeatureArticles'
import LinkTabs from 'components/LinkTabs'
import ImageCarouselBlock from 'components/ImageCarouselBlock'
import MarketInsightUsefulPagesBlock from 'components/MarketInsightUsefulPagesBlock'
import SavingProgressPopup from 'components/SavingProgressPopup'
import MarketInsightLinksBlock from 'components/MarketInsightLinksBlock'
import MarketInsightCalendarBlock from 'components/MarketInsightCalendarBlock'
import SasHtmlInjectionBlock from 'components/SasHtmlInjectionBlock'
import MarketInsightQAGroupBlock from 'components/MarketInsightQAGroupBlock'
import MarketInsightQABlock from 'components/MarketInsightQABlock'
import MarketInsightLatestBlogPostsBlock from 'components/MarketInsightLatestBlogPostsBlock'
import MarketInsightStatisticEventsList from 'components/MarketInsightStatisticEventsList'
import MarketInsightReportList from 'components/MarketInsightReportList'
import MarketInsightCtaLinkList from 'components/MarketInsightCtaLinkList';
import ArticleList from 'components/ArticleList';
import MarketInsightStatisticArchiveList from 'components/MarketInsightStatisticArchiveList';
import MarketInsightContactPersonsBlock from 'components/MarketInsightContactPersonsBlock';
import MarketInsightLinkWithDescriptionBlock from 'components/MarketInsightLinkWithDescriptionBlock';
import CustomsQuotasCTABlock from 'components/CustomsQuotasCTABlock';
import CustomsQuotasTermsPopup from 'components/CustomsQuotasTermsPopup';
import CustomsQuotasTable from 'components/CustomsQuotasTable';
import ScrollToLink from 'components/ScrollToLink';
import CountryPageSectionHeadingBlock from 'components/CountryPageSectionHeadingBlock';
import CountryPageImageVideoTextBlock from 'components/CountryPageImageVideoTextBlock';
import CountryPageLinksBlock from 'components/CountryPageLinksBlock';
import CountryPageSignUpForNewslettersBlock from 'components/CountryPageSignUpForNewslettersBlock';
import CountryPageLatestNewsBlock from 'components/CountryPageLatestNewsBlock';
import CountryPageLatestReportsAndPublicationsBlock from 'components/CountryPageLatestReportsAndPublicationsBlock'
import CountryPageActivitiesCampaignsBlock from 'components/CountryPageActivitiesCampaignsBlock';
import CountryPageRichTextBlock from 'components/CountryPageRichTextBlock';
import RichTextLinksBlock from 'components/RichTextLinksBlock';
import CountryPageHero from 'components/CountryPageHero';
import CountryPageLatestNewsOnMarketAccessBlock from 'components/CountryPageLatestNewsOnMarketAccessBlock';
import CountryPageTextCtaImageBlock from 'components/CountryPageTextCtaImageBlock';
import CountryPageSocialLink from 'components/CountryPageSocialLink'
import CountryPageEmployee from 'components/CountryPageEmployee'
import CountryPageContactUsBlock from 'components/CountryPageContactUsBlock';
import CountryPageWebinarsBlock from 'components/CountryPageWebinarsBlock';
import EmployeeCardList from 'components/EmployeeCardList';
import MarketInsightDownloadableStatisticsBlock from 'components/MarketInsightDownloadableStatisticsBlock';
import MarketInsightInteractiveReportsBlock from 'components/MarketInsightInteractiveReportsBlock';
import MarketInsightScrollToReportsBlock from 'components/MarketInsightScrollToReportsBlock';

// Expose React and ReactDOM to make @Html.ReactInitJavascript() work in .NET
window.React = React
window.ReactDOM = ReactDOM

// Expose the components used by ReactJS.net so that @Html.ReactInitJavascript()
// will find the components in the global scope
window.Components = {
    Marketplace,
    Offices,
    EventList,
    MaterialList,
    EmployeeList,
    SearchBlock,
    SearchPage,
    NewsFolder,
    ExporterRegistry,
    MarketGroup,
    EventListBlock,
    ReportList,
    PageSubscription,
    Newsletter,
    ImageInput,
    WeekMonthStatistics,
    NavigationAreas,

    ArticleCard,
    EmployeeCard,
    EmployeesBlock,
    Breadcrumbs,
    StepBlock,
    SharePanel,
    Pagination,
    AddToCalendar,
    SearchOverlay,
    Select,
    ScrollHint,
    StatisticsBlock,
    LatestArticlesBlock,
    LatestChroniclesBlock,
    PrioritizedMarketsBlock,
    PressContactsBlock,
    NewsPageHeader,
    JumpTo,
    Banner,
    ShortcutsBlock,
    SocialMediaLinksBlock,
    TopMediaBlock,
    CtaBlock,
    ImageBlock,
    EmbeddedFrame,
    HeroBlock,
    ParallaxEffectBlock,
    RichTextBlock,
    InfoBlock,
    FullPageQuotationBlock,
    FullPageImageBlock,
    ImageTextBlock,
    VideoBlock,
    FeatureArticleAuthor,
    FeatureArticleSources,
    MoreFeatureArticles,
    LinkTabs,
    ImageCarouselBlock,
    SavingProgressPopup,
    MarketInsightCalendarBlock,
    MarketInsightUsefulPagesBlock,
    MarketInsightLinksBlock,
    SasHtmlInjectionBlock,
    MarketInsightQAGroupBlock,
    MarketInsightQABlock,
    MarketInsightStatisticEventsList,
    MarketInsightLatestBlogPostsBlock,
    MarketInsightReportList,
    MarketInsightCtaLinkList,
    ArticleList,
    MarketInsightStatisticArchiveList,
    MarketInsightContactPersonsBlock,
    MarketInsightLinkWithDescriptionBlock,
    CustomsQuotasCTABlock,
    CustomsQuotasTermsPopup,
    CustomsQuotasTable,
    ScrollToLink,
    CountryPageRichTextBlock,
    RichTextLinksBlock,
    CountryPageImageVideoTextBlock,
    CountryPageSectionHeadingBlock,
    CountryPageLinksBlock,
    CountryPageHero,
    CountryPageSignUpForNewslettersBlock,
    CountryPageLatestNewsBlock,
    CountryPageLatestReportsAndPublicationsBlock,
    CountryPageActivitiesCampaignsBlock,
    CountryPageLatestNewsOnMarketAccessBlock,
    CountryPageTextCtaImageBlock,
    CountryPageSocialLink,
    CountryPageEmployee,
    CountryPageContactUsBlock,
    CountryPageWebinarsBlock,
    EmployeeCardList,
    MarketInsightDownloadableStatisticsBlock,
    MarketInsightInteractiveReportsBlock,
    MarketInsightScrollToReportsBlock
}

// Detect iOS - external link decoration fix
var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

if (iOS) {
    document.body.classList.add('iOS')
}
