import React from 'react'
import PropTypes from 'prop-types'

import Label from 'components/Label'

const formatSpecies = species => {
    if(! species) return null

    const fallback = species.map(c => c.name).join(', ')

    if(species.length >= 3) {
        return <Label fallback={fallback} label="multipleSpecies" />
    }

    return fallback
}

const Species = ({ species }) => <span>{formatSpecies(species)}</span>

Species.propTypes = {
    species: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired
    }))
}

export default Species
