import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import LinkWithIcon from 'components/LinkWithIcon'
import './CountryPageEmployee.scss'

const CountryPageEmployee = ({
    image,
    name,
    position,
    url  
}) => {
    return (
        <div  className="country-page-employee">
            <div className="country-page-employee__image-container">
                {image && 
                    <ResponsiveImage 
                    {...image} 
                    className="responsive-image_size_country-page-employee" 
                />}
            </div>
            <div className="country-page-employee__text-container">
                {name && 
                    <LinkWithIcon  
                        className="country-page-employee__name link-with-icon_in-country-page-contact-us-block" 
                        url={url} 
                        text={name} 
                    />
                }
                {position && <div className="country-page-employee__position">{position}</div>}
            </div>
        </div>
    )
}

CountryPageEmployee.propTypes = {
    url: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    name: PropTypes.string,
    position: PropTypes.string,
}

export default CountryPageEmployee
