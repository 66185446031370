import React from 'react'
import PropTypes from 'prop-types'

import SearchWrapper from 'components/SearchWrapper'

import * as css from './FilterSearch.scss'

class FilterSearch extends React.Component {
    componentDidMount() {
        if(typeof window !== "undefined") {
            window.addEventListener('seafood-filter-reset', () => {
                const input = this.search.querySelector('input')
                if(input && input.value) {
                    input.value = ''
                    this.props.onChange({ target: input })
                }
            })
        }
    }

    render() {
        const { labels, onChange, onClick, searching, searchAriaLabel, query } = this.props
        return (
            <div ref={node => this.search = node}>
                <SearchWrapper
                    buttonLabel={labels.search}
                    className={css.search}
                    defaultValue={query || ""}
                    onChange={onChange}
                    onClick={onClick}
                    placeholder={labels && labels.title}
                    searching={searching}
                    ariaLabel={searchAriaLabel}
                />
            </div>
        )
    }
}

FilterSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    labels: PropTypes.shape({
        title: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired
    }).isRequired,
    searching: PropTypes.bool.isRequired,
    query: PropTypes.string,
    searchAriaLabel: PropTypes.string,
}

export default FilterSearch
