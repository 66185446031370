import React from 'react'
import PropTypes from 'prop-types'
import EmployeeCard from 'components/EmployeeCard'
import Link from 'components/Link'
import './PressContactsBlock.scss'
import Anchor from 'components/Anchor'


const PressContactsBlock = ({ topContact, topContactDownloadLink, pressContacts, anchor }) => {
    return (
        <div className="press-contacts-block">
            {anchor && <Anchor {...anchor} />}
            {topContact && (
                <div className="press-contacts-block__top-contact">
                    <div className="press-contacts-block__top-contact-wrapper">
                        <EmployeeCard 
                            {...topContact} 
                            view="presstopcontact" 
                            customDownloadLink={topContactDownloadLink}
                        />
                    </div>
                </div>
            )}

            {pressContacts && pressContacts.contacts && (
                <div className="press-contacts-block__press-contacts">
                    {pressContacts.heading && (
                        <h2 className="press-contacts-block__heading h4">
                            {pressContacts.heading}
                        </h2>
                    )}
                    {pressContacts.seeAllLink && (
                        <Link
                            {...pressContacts.seeAllLink}
                            className="press-contacts-block__see-all-link see-all shortcut"
                        />
                    )}

                    <ul className="press-contacts-block__contacts">
                        {pressContacts.contacts.map((contact, index) => (
                            <li
                                key={index}
                                className="press-contacts-block__contact"
                            >
                                <EmployeeCard {...contact} negative={false} view={"marketgroup"} />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

PressContactsBlock.propTypes = {
    anchor: PropTypes.exact(Anchor.propTypes),
    topContact: PropTypes.exact(EmployeeCard.propTypes),
    topContactDownloadLink: PropTypes.exact(Link.propTypes),
    pressContacts: PropTypes.shape({
        heading: PropTypes.string,
        seeAllLink: PropTypes.exact(Link.propTypes),
        contacts: PropTypes.arrayOf(PropTypes.exact(EmployeeCard.propTypes))
    })
}

export default PressContactsBlock
