import React, { useState } from 'react'
import PropTypes from 'prop-types';
import SelectFilter from 'components/SelectFilter/SelectFilter';
import FILTER_SIZES from 'components/SelectFilter/filter-sizes';
import FILTER_VARIANTS from 'components/SelectFilter/filter-variants';
import DateRangeFilter from 'components/DateRangeFilter/DateRangeFilter';
import useDidUpdateEffect from '../../hooks/use-did-update-effect';
import cn from 'classnames';
import moment from 'moment';

import './SelectFiltersPanel.scss';

const dateFormat = 'YYYY-MM-DD';

const SelectFiltersPanel = ({ 
	query, 
	selectFilters, 
	periodFilter, 
	expanderLabel, 
	noResultsText,
	multipleSelection = true,
	filterSize = FILTER_SIZES.MEDIUM,
	filterVariant = FILTER_VARIANTS.ROUNDED,
	className,
	overrideFiltersFromServer = false,
	onFilterChange = () => {} 
}) => {
	const [filtersExpanded, setFiltersExpanded] = useState(false);
	const [selectFilterItems, setSelectFilterItems] = useState(selectFilters)
    const [periodFilterState, setPeriodFilterState] = useState({
		from: null,
		to: null
	})

	const fromParamName = periodFilter ? periodFilter.fromName : '';
	const toParamName = periodFilter ? periodFilter.toName : '';

    useDidUpdateEffect(() => {
        // Set filters state to handle selected values in filters
		if (selectFilters && selectFilters.length > 0) {
			setSelectFilterItems(filters => {
				const updatedFilters = [...filters].map(filter => (
					filter ? {
						...filter,
						items: filter.items.map(item => ({
							...item,
							selected: Array.isArray(query[filter.name]) ? 
								!!query[filter.name].includes(item.value) : 
								query[filter.name] == item.value
						}))
					} : filter
				))
				return updatedFilters
			})
		}
       
        // Set filters period state to handle selected dates in filters
		setPeriodFilterState({
            from: query[fromParamName],
            to: query[toParamName]
        })
    }, [query]);

	useDidUpdateEffect(() => {
		if (overrideFiltersFromServer) {
			setSelectFilterItems(selectFilters)
		}
	}, [selectFilters, overrideFiltersFromServer]);

	const toggleFilters = () => setFiltersExpanded(prev => !prev);

	const onFilterChanged = (name, value, dependantFilterNames) => {
        const values = multipleSelection ? value.map(value => value.value) : value.value
        const updatedQuery = {...query}

        if (values && values.length > 0) {
            updatedQuery[name] = values
        } else {
            delete updatedQuery[name]
        }

		if (dependantFilterNames && dependantFilterNames.length > 0) {
			for (let i = 0; i < dependantFilterNames.length; i++) {
				const dependantFilterName = dependantFilterNames[i]

				if (dependantFilterName) {
					delete updatedQuery[dependantFilterName]
				}
			}
		}

        onFilterChange(updatedQuery)
    }

	const onPeriodFilterChanged = (dates) => {
		const updatedQuery = {...query}

		if (dates.startDate) {
			updatedQuery[fromParamName] = moment(dates.startDate).format(dateFormat).substring(0, 10)
		} else {
			delete updatedQuery[fromParamName]
		}

		if (dates.endDate) {
			updatedQuery[toParamName] = moment(dates.endDate).format(dateFormat).substring(0, 10)
		} else {
			delete updatedQuery[toParamName]
		}
		
		onFilterChange(updatedQuery)
	}

	return (
		<div className={cn('select-filters-panel', className)}>
			<button 
				className='select-filters-panel__expander hidden-desktop'
				onClick={toggleFilters}
			>
				{expanderLabel}
			</button>
			<div className={cn('select-filters-panel__list', {
				'select-filters-panel__list_hidden': !filtersExpanded
			})}>
				{
					selectFilterItems && selectFilterItems.length > 0 ? (
						selectFilterItems.map((filter, index) => (
							filter ? <div className='select-filters-panel__item' key={index}>
								<SelectFilter 
									{...filter} 
									multiple={multipleSelection}
									noResultsText={noResultsText}
									size={filterSize}
									variant={filterVariant}
									onChange={onFilterChanged}
								/>
							</div> : null
						))
					) : null
				}
				{periodFilter && (
					<div className='select-filters-panel__item'>
						<DateRangeFilter {...periodFilter} {...periodFilterState} onChange={onPeriodFilterChanged} />
					</div>
				)}
			</div>
		</div>
	)
};

SelectFiltersPanel.propTypes = {
	query: PropTypes.object,
	expanderLabel: PropTypes.string,
	noResultsText: PropTypes.string,
	multipleSelection: PropTypes.bool,
	selectFilters: PropTypes.arrayOf(PropTypes.exact(SelectFilter.propTypes)),
	periodFilter: PropTypes.exact(SelectFilter.propTypes),
	className: PropTypes.string,
	filterVariant: PropTypes.oneOf(Object.values(FILTER_VARIANTS)),
	filterSize: PropTypes.oneOf(Object.values(FILTER_SIZES)),
	overrideFiltersFromServer: PropTypes.bool,
	onFilterChange: PropTypes.func
};

SelectFiltersPanel.propTypesMeta = 'exclude';

export default SelectFiltersPanel;
