import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import moment from 'moment'

import Icon from 'components/Icon'
import FilterDateRange from 'components/FilterDateRange'
import FilterCategory from 'components/FilterCategory'
import FilterValue from 'components/FilterValue'

import * as css from './Filters.scss'

import FilterType from 'types/FilterType'

class Filters extends React.Component {
    static propTypes = {
        labels: PropTypes.shape({
            showFilters: PropTypes.string.isRequired,
            hideFilters: PropTypes.string.isRequired,
            filterButton: PropTypes.string.isRequired,
            reset: PropTypes.string.isRequired
        }).isRequired,
        rssLink: PropTypes.shape({
            link: PropTypes.string,
            text: PropTypes.string
        }),
        externalLink: PropTypes.shape({
            link: PropTypes.string,
            text: PropTypes.string
        }),
        internalLinks: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                text: PropTypes.string
            })
        ),
        tabs: PropTypes.arrayOf(
            PropTypes.shape({
                active: PropTypes.bool.isRequired,
                link: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired
            })
        ),
        children: PropTypes.node,
        negative: PropTypes.bool,
        filters: PropTypes.arrayOf(PropTypes.shape(FilterType)),
        onFilter: PropTypes.func.isRequired,
        showItemCount: PropTypes.bool.isRequired,
        totalItems: PropTypes.number.isRequired,
        filtersOpen: PropTypes.bool.isRequired
    }

    static defaultProps = {
        labels: {},
        showItemCount: false,
        filtersOpen: false
    }

    state = {
        collapsed: true,
        activeFilters: {},
        dateRange: this._getDefaultDateRange(this.props)
    }

    componentDidMount() {
        if (this.props.filtersOpen) {
            this.setState({ collapsed: false })
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('seafood-filter-reset', this._onReset)
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        // TODO: antipattern?
        if (props.filtersOpen !== this.props.filtersOpen) {
            this.setState({ collapsed: !props.filtersOpen })
        }
    }

    _getDefaultDateRange(props) {
        if (!props || !props.filters) return null

        const dateRange = props.filters.find(f => f.dateRange)

        if (!dateRange || !dateRange.value) return null

        return {
            startDate: new moment(dateRange.value.startDate),
            endDate: new moment(dateRange.value.endDate)
        }
    }

    _onChange = (id, value) => {
        let newValue = value.map(x => (x.value));
        let valueToString = newValue.join(",")

        if (!value) {
            let activeFilters = { ...this.state.activeFilters }
            delete activeFilters[id]
            this.setState({ activeFilters })
        } else {
            this.setState({
                activeFilters: {
                    ...this.state.activeFilters,
                    [id]: valueToString || null
                }
            })
        }
    }

    _onReset = () => {
        // Reset filter search input
        if (this.filters && this.filters.parentElement) {
            const search = this.filters.parentElement.querySelector(
                '.search-wrapper input'
            )
            if (search && search.value) search.value = ''
        }

        this.setState(
            {
                activeFilters: {},
                dateRange: this._getDefaultDateRange(this.props)
            },
            this.props.onFilter
        )
    }

    _onFilter = () => {
        const { activeFilters, dateRange } = this.state

        let filter = {
            ...activeFilters
        }

        if (dateRange && dateRange.startDate && dateRange.endDate) {
            filter.date = dateRange.startDate.toISOString()
            filter.endDate = dateRange.endDate.toISOString()
        }

        this.props.onFilter(filter)
    }

    _onRangeChange = dateRange => this.setState({ dateRange })
    _toggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed })

    render() {
        const {
            children,
            labels,
            filters,
            negative,
            externalLink,
            internalLinks,
            rssLink,
            showItemCount,
            totalItems,
            tabs
        } = this.props

        const { collapsed, activeFilters, dateRange } = this.state

        const hasRssLink = rssLink && rssLink.link
        const hasExternalLink = externalLink && externalLink.link
        const hasInternalLinks =
            internalLinks && internalLinks.filter(l => l.link)
        const hasFilters = (filters && !!filters.length) || children

        return (
            <div
                className={cn({
                    [css.collapsed]: collapsed
                })}
                data-negative={negative}
                ref={node => (this.filters = node)}
            >
                <div
                    className={cn(css.buttons, {
                        [css.hasExternalLink]: hasExternalLink
                    })}
                >
                    {hasRssLink && (
                        <p
                            className={cn(css.rssLink, {
                                [css.hasExternalLink]: hasExternalLink
                            })}
                        >
                            <a
                                className="external external-dark"
                                href={rssLink.link}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {rssLink.text} <Icon use="rss" />
                            </a>
                        </p>
                    )}
                    {hasExternalLink && (
                        <a
                            className={cn(
                                'external external-dark',
                                css.externalLink
                            )}
                            href={externalLink.link}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {externalLink.text}
                        </a>
                    )}
                    {hasInternalLinks &&
                        hasInternalLinks.map(link => (
                            <a
                                className={cn('button small', css.internalLink)}
                                href={link.link}
                                key={link.link}
                            >
                                {link.text}
                            </a>
                        ))}
                    {tabs && (
                        <ul className={css.tabs}>
                            {tabs.map(tab => (
                                <li key={tab.link}>
                                    <a
                                        className={cn('tag tag-large', {
                                            active: tab.active
                                        })}
                                        href={tab.link}
                                    >
                                        {tab.text}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                    {hasFilters && (
                        <button
                            className={cn(
                                'button secondary toggle',
                                css.toggleFilters
                            )}
                            onClick={this._toggleCollapsed}
                        >
                            {labels && collapsed && labels.showFilters}
                            {labels && !collapsed && labels.hideFilters}
                            <Icon use={collapsed ? 'caret-down' : 'caret-up'} />
                        </button>
                    )}
                    {showItemCount &&
                        labels.itemsSingular &&
                        labels.itemsPlural && (
                            <div 
                                className={cn(css.itemCount, 'h3')} 
                                aria-live='polite' 
                                aria-atomic='true' 
                                {...(totalItems === 0 && { "aria-describedby": "no-results-container" })}
                            >
                                {totalItems === 1
                                    ? labels.itemsSingular.format(totalItems)
                                    : labels.itemsPlural.format(totalItems)}
                            </div>
                        )}
                </div>
                {(filters || children) && (
                    <div
                        className={cn(css.filters, {
                            [css.negative]: negative
                        })}
                        data-filters={
                            filters
                                ? filters.length
                                : children && children.length
                        }
                    >
                        {filters &&
                            filters.map(f => {
                                if (f.categories || f.types) {
                                    return (
                                        <FilterCategory
                                            {...f}
                                            key={f.labels.title}
                                            onChange={this._onChange}
                                            value={activeFilters[f.type]}
                                        />
                                    )
                                } else if (f.values) {
                                    return (
                                        <FilterValue
                                            {...f}
                                            key={f.name}
                                            onChange={this._onChange}
                                            value={activeFilters[f.name]}
                                        />
                                    )
                                } else if (f.dateRange) {
                                    return (
                                        <FilterDateRange
                                            {...f}
                                            key={f.labels.title}
                                            negative={negative}
                                            onChange={this._onRangeChange}
                                            value={dateRange}
                                        />
                                    )
                                }
                            })}
                        {children}
                        {filters && [
                            <button
                                className="button small"
                                key="submit"
                                onClick={this._onFilter}
                            >
                                {labels && labels.filterButton}
                            </button>,
                            <button
                                className="button secondary small"
                                key="reset"
                                onClick={this._onReset}
                            >
                                {labels && labels.reset}
                            </button>
                        ]}
                    </div>                    
                )}
            </div>
        )
    }
}

export default Filters
