// extracted by mini-css-extract-plugin
var _1 = "BmRvPk6KU4ctLwY9qDUQ";
var _2 = "r_m7RRLuWeKXzeqbqNoA";
var _3 = "nCc6Yfk2_2tc8hfisDEE";
var _4 = "Ls0QxzyA4wgSpch04qwP";
var _5 = "eAlx7R93nbwWUXWVL0g8";
var _6 = "cRyPPNIcySKibFN8xexh";
var _7 = "Z8Il3ZXaCEnd4j4CkRHV";
var _8 = "pVGyoMGbeLGHPIyepT5a";
var _9 = "EJB_rnus3F5tLJ_CtK1p";
var _a = "Z3VB9EuuPwn0B2Nwd0Nj";
export { _1 as "button", _2 as "hide", _3 as "negative", _4 as "next", _5 as "noNext", _6 as "page", _7 as "pagination", _8 as "paginationIcon", _9 as "prev", _a as "reverse" }
