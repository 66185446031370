// extracted by mini-css-extract-plugin
var _1 = "a_G16wBgF3ma9deK76YI";
var _2 = "mWanGUHwamf6bFzJVdyj";
var _3 = "t8Z5XJbuwrEm7ZcWybV1";
var _4 = "tIpbuD5IDEaRRuC2ugqD";
var _5 = "DFBDbRPfXOVRaHVKUz3D";
var _6 = "BFO6pQcPZctDMhvg5HtA";
var _7 = "wc5EPNigj4o5kFZoBM8C";
var _8 = "WxX48dCAibqhctdvAKqY";
var _9 = "z_jIAwmPor24iZhZf404";
var _a = "XdoR_JYHOkp9Ed1dJ5hg";
export { _1 as "categories", _2 as "employee", _3 as "header", _4 as "hover", _5 as "image", _6 as "intro", _7 as "meta", _8 as "news", _9 as "no-access", _a as "result" }
