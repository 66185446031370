import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Modal from 'components/Modal'
import Icon from 'components/Icon'

import * as css from './PageSubscription.scss'

class PageSubscription extends React.Component {
    static propTypes = {
        description: PropTypes.string.isRequired,
        labels: PropTypes.shape({
            cancel: PropTypes.string.isRequired,
            close: PropTypes.string.isRequired,
            goToMyPage: PropTypes.string.isRequired,
            subscribe: PropTypes.string.isRequired
        }).isRequired,
        isSubscribing: PropTypes.bool.isRequired,
        url: PropTypes.string.isRequired,
        className: PropTypes.string
    }

    static defaultProps = {
        labels: {}
    }

    state = {
        open: false
    }

    _toggleOpen = () => this.setState({ open: !this.state.open })
    _closeModal = () => {
        const close = document.querySelector('[data-modal="close"]')

        if(close && close.click) {
            close.click()
        } else {
            this._toggleOpen()
        }
    }

    render() {
        const {
            description,
            labels,
            url,
            className
        } = this.props

        const {
            open
        } = this.state

        return (
            <div>
                <div 
                    className={cn("page-subscription", className)} 
                    onClick={this._toggleOpen}
                >
                    <Icon use="subscription" className="page-subscription__icon" />
                    {labels.subscribe}</div>
                {open && (
                    <Modal 
                        hasImage={true} 
                        labels={labels}
                        onClose={this._toggleOpen}
                    >
                        <div className="page-subscription__image" />
                        <div className="page-subscription__content">
                            <div 
                                className="wysiwyg" 
                                dangerouslySetInnerHTML={{__html: description}}
                            />
                            <div className={cn("page-subscription__actions", "wysiwyg")}>
                                <a className="button small" href={url}>{labels.goToMyPage}</a>
                                <a className="page-subscription__cancel" onClick={this._closeModal}>{labels.cancel}</a>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default PageSubscription
