import React from 'react'
import PropTypes from 'prop-types'
import CountryPageLatestNewsOnMarketAccessBlockItem from './CountryPageLatestNewsOnMarketAccessBlockItem'
import LinkWithIcon from 'components/LinkWithIcon'
import './CountryPageLatestNewsOnMarketAccessBlock.scss'
import Link from 'components/Link'

const CountryPageLatestNewsOnMarketAccessBlock = ({ heading, news, lang, newsArchiveLink}) => {
    return (
        <div className="country-page-latest-news-on-market-access-block">
                {heading && <h2 className="country-page-latest-news-on-market-access-block__heading">{heading}</h2>}
                {news && <ul className="country-page-latest-news-on-market-access-block__list">
                    {news.map((item, index) => (
                        <li key={index} className="country-page-latest-news-on-market-access-block__list-item">
                            <CountryPageLatestNewsOnMarketAccessBlockItem 
                                {...item} 
                                pablishedLabel={lang.publishedLabel}
                                headingTagLevel={heading ? 3 : 2}
                            />
                        </li>
                    ))}    
                </ul>}
                {newsArchiveLink && 
                    <LinkWithIcon 
                        {...newsArchiveLink} 
                        className="country-page-latest-news-on-market-access-block__archive-link" 
                    />
                }
        </div>
    )
}

CountryPageLatestNewsOnMarketAccessBlock.propTypes = {
    heading: PropTypes.string,
    news: PropTypes.arrayOf(PropTypes.exact(CountryPageLatestNewsOnMarketAccessBlockItem.propTypes)).isRequired,
    lang: PropTypes.shape({
        publishedLabel: PropTypes.string.isRequired,
    }).isRequired,
    newsArchiveLink:PropTypes.exact(Link.propTypes)
}

export default CountryPageLatestNewsOnMarketAccessBlock
