// extracted by mini-css-extract-plugin
var _1 = "TULlskHOdvwkgb73QJ5t";
var _2 = "FHERNMKwkaTFiOBNcLEe";
var _3 = "Xc08CG_yuAgfT7hT3KmT";
var _4 = "tawqeIzJ60QX0k_CxVmD";
var _5 = "Iu9jwApqo9G1cdG_Iisw";
var _6 = "LSU4vwhgeEX8OB233am6";
var _7 = "mNWaAzRR_XzDlkxhaCQP";
var _8 = "cvJOPLTWotlecD_omCrQ";
export { _1 as "contactActions", _2 as "contactText", _3 as "header", _4 as "image", _5 as "info", _6 as "intro", _7 as "name", _8 as "profileImage" }
