import React, { useState, useMemo  } from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage';
import './CountryPageImageVideoTextBlock.scss'
import RichText from 'components/RichText';
import PoorText from 'components/PoorText';
import EmbeddedVideo from 'components/EmbeddedVideo'
import Icon from 'components/Icon';
import cn from 'classnames';
import idHelper from 'js/id-helper'
import { replaceStringPlaceholders } from '../../js/string-helper'

const CountryPageImageVideoTextBlock = ({ heading, textHtml, image, videoUrl, videoTranscriptionHtml, lang  }) => {
    const [isTranscriptionExpanded, setIsTranscriptionExpanded] = useState(false);

    const onViewTranscriptionButtonClick = e => {
        e.preventDefault();
        setIsTranscriptionExpanded(prevIsTranscriptionExpanded => !prevIsTranscriptionExpanded)
    }

    const uniqueExpandablePanelId = useMemo(
        () => idHelper.generateUniqueId('video-transcription-expandable-panel'),
        []
    );
   
    return (
        <div className="country-page-image-video-text-block">
            {heading && <h2 className="country-page-image-video-text-block__heading">{heading}</h2>}
            <div className="country-page-image-video-text-block__content">
                <div className="country-page-image-video-text-block__media-content">
                {videoUrl ? 
                <EmbeddedVideo 
                    videoUrl={videoUrl} 
                    playing={false}
                    controls={true}
                />
                : image && <ResponsiveImage {...image} className="responsive-image_size_country-page-image-video-text-block" />}  
                </div>
                <div className="country-page-image-video-text-block__text-content">
                
                {textHtml && <RichText text={textHtml} className="rich-text_in-country-page-image-video-text-block" />}
                {videoTranscriptionHtml && 
                    <React.Fragment>
                        <button 
                            aria-controls={uniqueExpandablePanelId} 
                            aria-expanded={isTranscriptionExpanded}
                            onClick={onViewTranscriptionButtonClick}
                            className={cn('country-page-image-video-text-block__expand-button', {
                                ['country-page-image-video-text-block__expand-button_active'] : isTranscriptionExpanded
                            })}
                        >
                            <span className="country-page-image-video-text-block__expand-button-text">{isTranscriptionExpanded ? lang.hideTranscriptionLabel : lang.showTranscriptionLabel}</span>
                            <Icon use="arrow-up"className="country-page-image-video-text-block__expand-button-icon" />
                        </button>
                        <div
                            aria-label={replaceStringPlaceholders(
                                lang.transcriptionFormatAriaLabel,
                                heading ? heading : ''
                            )}
                            id={uniqueExpandablePanelId}
                            role="region"
                            className={cn('country-page-image-video-text-block__expandable-panel', {
                                ['country-page-image-video-text-block__expandable-panel_expanded'] : isTranscriptionExpanded
                            })}
                        >
                            <PoorText  text={videoTranscriptionHtml} className="poor-text_in-country-page-image-video-text-block" />
                        </div>
                    </React.Fragment>
                }</div>
            </div>            
        </div>
    )
}

CountryPageImageVideoTextBlock.propTypes = {
    heading: PropTypes.string,
    textHtml: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    videoUrl: PropTypes.string,
    videoTranscriptionHtml: PropTypes.string,
    lang: PropTypes.shape({
        showTranscriptionLabel: PropTypes.string.isRequired,  
        hideTranscriptionLabel: PropTypes.string.isRequired,
        transcriptionFormatAriaLabel: PropTypes.string.isRequired,
    }).isRequired,  
}

export default CountryPageImageVideoTextBlock
