import PropTypes from 'prop-types'

const EventType = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    startDateShortFormatted: PropTypes.string,
    endDateShortFormatted: PropTypes.string,
    city: PropTypes.string.isRequired,
    country: PropTypes.string,
    linkUrl: PropTypes.string.isRequired,
    isExpired: PropTypes.bool
}

export default EventType