export const DatePeriod = {
    past: "past",
    today: "today",
    upcoming: "upcoming"
}

export const MARKET_INSIGHT_STATISTICS_BLOCK_IDS = {
    interactiveReports: 'interactive-reports',
    downloadableStatistics: 'downloadable-statistics'
}
