import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const LabelsContext = createContext({});

export const LabelsProvider = ({ labels, children }) => (
    <LabelsContext.Provider value={labels}>{children}</LabelsContext.Provider>
);

LabelsProvider.propTypes = {
    labels: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};