import React from 'react'
import PropTypes from 'prop-types'
import './FeatureArticleSources.scss'
import RichText from 'components/RichText'

const FeatureArticleSources = ({heading, textHtml }) => (
    <div className="feature-article-sources">
        <div className="feature-article-sources__container">
            {heading && <h2 className="feature-article-sources__heading">{heading}</h2>}
            {textHtml && <RichText text={textHtml} className="rich-text_in-feature-article-sources" />}
        </div>
    </div>
)

FeatureArticleSources.propTypes = {
    heading: PropTypes.string,
    textHtml: PropTypes.string,
}

export default FeatureArticleSources
