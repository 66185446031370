import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import './FeatureArticleAuthor.scss'

const FeatureArticleAuthor = ({
    image,
    firstName,
    lastName,
    email,
    title,
    publishedDate
}) => (
    <div className="feature-article-author">
        {image && (
            <div className="feature-article-author__image-container">
                <ResponsiveImage
                    {...image}
                    className="responsive-image_size_feature-article-author"
                />
            </div>
        )}
        <div className="feature-article-author__text-container">
            {email ? (
                <a
                    href={'mailto:' + email}
                    className="feature-article-author__name feature-article-author__name_clickable"
                >
                    {firstName} {lastName}
                </a>
            ) : (
                <strong className="feature-article-author__name">
                    {firstName} {lastName}
                </strong>
            )}
            {title && (
                <div className="feature-article-author__title">{title}</div>
            )}
            {publishedDate && (
                <div className="feature-article-author__published-date">
                    {publishedDate}
                </div>
            )}
        </div>
    </div>
)

FeatureArticleAuthor.propTypes = {
    image: PropTypes.exact(ResponsiveImage.propTypes),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    publishedDate: PropTypes.string
}

export default FeatureArticleAuthor
