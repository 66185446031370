import React from 'react'
import PropTypes from 'prop-types'
import RichText from 'components/RichText'

import './RichTextBlock.scss'

const RichTextBlock = ({ textHtml, children }) => {
    return (
        (textHtml || children) ? <div
            className="rich-text-block"
        >
            <RichText text={textHtml}>
                {children}
            </RichText>
        </div> : null
    )
}

RichTextBlock.propTypes = {
    textHtml: PropTypes.string,
    children: PropTypes.node
}

export default RichTextBlock
