import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from 'components/Icon'

import * as css from './StatisticLink.module.scss'

class StatisticLink extends React.PureComponent {
    static propTypes = {
        "className": PropTypes.string,
        "id": PropTypes.number.isRequired,
        "title": PropTypes.string.isRequired,
        "size": PropTypes.string.isRequired,
        "filetype": PropTypes.string.isRequired,
        "url": PropTypes.string.isRequired,
        "hasAccess": PropTypes.bool.isRequired,
        "isLoggedIn": PropTypes.bool.isRequired,
    }

    render() {
        const {
            className,
            url,
            title,
            size,
            filetype,
            hasAccess,
        } = this.props

        return (
            <a
                className={cn(className, css.report, {
                    [css.closed]: !hasAccess
                })}
                href={url}
            >
                <Icon use={hasAccess ? "download" : "key"} />
                <span>{title}</span>
                <span className={css.details}>
                    ({size} / {filetype})
                </span>
            </a>
        )
    }
}

export default StatisticLink
