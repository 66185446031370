import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import ScrollHint from 'components/ScrollHint'
import cn from 'classnames'

import * as css from './ShortcutsBlock.scss'
import { useEffect, useState } from 'react'

const ShortcutsBlock = ({
    groups,
    scrollButtonLabel
}) => {
    const shortcutsBlock = useRef(null)
    const [scrollToPosition, setScrollToPosition] = useState(0)

    const getScrollToPosition = () => {
        let headerHeight = 0;
        const header = document.querySelector("#top-menu");

        if (header) {
            headerHeight = header.offsetHeight;
        }

        const blockHeight = shortcutsBlock.current.offsetHeight;
        const blockOffset = shortcutsBlock.current.offsetTop;

        return blockHeight + blockOffset;
    }

    useEffect(() => {
        if (shortcutsBlock.current) {
            setScrollToPosition(getScrollToPosition())
        }
    }, [shortcutsBlock])

    return (
        <div className={css.shortcutsBlock} ref={shortcutsBlock}>
            <div className={css.shortcutsWrapper}>
                {
                    groups && groups.map((group, index) => (
                        <div key={index} className={css.shortcutsCol}>
                            <h2 className={cn("h4", css.shortcutsHeading)}>{group.heading}</h2>
                            <ul>
                                {group.links &&
                                    group.links.map((link, index) => (
                                        <li key={index} className={css.shortcutsItem}>
                                            <Link 
                                                {...link} 
                                                className={cn('shortcut-reverse', css.shortcutsLink)}
                                            />
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    ))
                }
            </div>
            <ScrollHint 
                className={css.shortcutsScrollBtn} 
                hideOnScroll={false}
                scrollToPosition={scrollToPosition}
                label={scrollButtonLabel} 
            />
        </div>
    )
}

ShortcutsBlock.propTypes = {
    scrollButtonLabel: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string,
        links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
    }))
}

export default ShortcutsBlock